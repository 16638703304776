import { useContext, useEffect, useState } from "react";
import {
  average,
  max,
  min,
  quantile,
  standardDeviation,
} from "simple-statistics";
import { ReactComponent as BarChartIcon } from "../../../../assets/img/icon/bar_chart_black_24dp.svg";
import { ReactComponent as BoardIcon } from "../../../../assets/img/icon/developer_board_black_24dp.svg";
// import { ReactComponent as ListIcon } from "../../../../assets/img/icon/insights_black_24dp.svg";
import { ReactComponent as FilterIcon } from "../../../../assets/img/icon/filter_alt_black_24dp.svg";
import { ReactComponent as PrintIcon } from "../../../../assets/img/icon/print_black_24dp.svg";
import { CONFIG, GRAPH_COLOR } from "../../../../configs/constant";
import {
  formatter,
  getDateFormat2,
  getDateInputFormat,
  getDateTimeFormat,
} from "../../../../helpers/helper";
import { getDevice } from "../../../../services/httpServices/deviceService";
import { getPayload } from "../../../../services/httpServices/payloadService";
import { GlobalContext } from "../../../contexts/globalContext";
import CardDashboard from "../../../ui/atoms/CardDashboard/CardDashboard";
import PageTitle from "../../../ui/atoms/PageTitle/PageTitle";
import ChartLine from "../../../ui/molecules/LineChart/LineChart";
import ModalStatisticFilter from "../../../ui/molecules/ModalStatisticFilter/ModalStatisticFilter";

const dateNow = new Date();
let prevDate = new Date();
prevDate.setDate(prevDate.getDate() - 0);

const statistics = [
  { label: "Count" },
  { label: "Average", cb: average },
  { label: "Standard Deviation", cb: standardDeviation },
  { label: "Minimum", cb: min },
  { label: "25%", cb: (data) => quantile(data, 0.25) },
  { label: "50%", cb: (data) => quantile(data, 0.5) },
  { label: "75%", cb: (data) => quantile(data, 0.75) },
  { label: "Maximum", cb: max },
];

const Statistic = () => {
  const [devices, setDevices] = useState([]);
  const [payload, setPayload] = useState([]);
  const [isDone, setIsDone] = useState(false);

  const [modal, setModal] = useState(false);
  const [sharedState, dispatch] = useContext(GlobalContext);
  const [state, setState] = useState({
    deviceName: "",
    startDate: getDateInputFormat(prevDate),
    startTime: "00:00",
    endDate: getDateInputFormat(dateNow),
    endTime: "23:59",
  });

  const [result, setResult] = useState({});

  const handleGetDevice = async () => {
    try {
      let response = await getDevice(`application=${CONFIG.appName}`);
      response.result = response.result.filter(
        (obj) => !CONFIG.root.includes(obj.deviceName)
      );

      if (response.result.length === 0) return;
      setDevices(response.result);
      setState((prevState) => ({
        ...prevState,
        deviceName: response.result[0].deviceName,
      }));
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleGetPayload = async () => {
    try {
      dispatch("isLoading", true);
      if (
        state.startDate === "" ||
        state.startTime === "" ||
        state.endDate === "" ||
        state.endTime === ""
      )
        throw new Error("Date or time can't be empty.");
      let tStartDate = new Date(`${state.startDate} ${state.startTime}`);
      let tEndDate = new Date(`${state.endDate} ${state.endTime}`);

      if (tStartDate.getTime() > tEndDate.getTime()) {
        throw new Error("Invalid Range");
      }

      tStartDate = getDateTimeFormat(tStartDate);
      tEndDate = getDateTimeFormat(tEndDate);

      const { result, count } = await getPayload(
        `topics=${CONFIG.appName} - ${state.deviceName}&startDate=${tStartDate}&endDate=${tEndDate}`
      );

      let updatedPayload = result[`${CONFIG.appName} - ${state.deviceName}`];
      if (updatedPayload.length === 0) throw new Error("Data sensor not found");
      updatedPayload = updatedPayload.map((payload) => {
        payload["timestamp"] = getDateFormat2(payload["timestamp"]);
        return payload;
      });
      updatedPayload = updatedPayload.reverse();
      setPayload(updatedPayload);
      calcStat(updatedPayload);
      dispatch("isLoading", false);
      setModal(false);
    } catch (error) {
      dispatch("alert", {
        show: true,
        type: "warning",
        message: error.message,
      });
      dispatch("isLoading", false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const calcStat = (inputPayload) => {
    let keys = CONFIG.variable.map((obj) => obj.key);
    let data = {};
    keys.forEach((key) => {
      data[key] = {};
      data[key]["data"] = [];
    });

    // ASSIGN THE PAYLOAD
    inputPayload.forEach((payload) => {
      keys.forEach((key) => {
        // if (+payload.payload[key])
        let value = +payload.payload[key];
        if (!isNaN(value)) data[key]["data"] = [...data[key]["data"], value];
      });
    });

    // CALCULATE STATISTIC
    keys.forEach((key) => {
      statistics.forEach((stat) => {
        if (stat.cb && data[key]["data"].length !== 0)
          data[key][stat.label] = formatter.format(stat.cb(data[key]["data"]));
      });
    });
    // CALCULATE COUNT
    keys.forEach((key) => {
      data[key]["Count"] = data[key]["data"].length;
    });
    setResult(data);
    setIsDone(true);
  };

  useEffect(() => {
    handleGetDevice();
    // calcStat();
  }, []);

  return (
    <div className="mt-2 lg:mt-6">
      <ModalStatisticFilter
        show={modal}
        handleClose={() => setModal(false)}
        handleSubmit={(param) => handleGetPayload(param)}
        devices={devices}
        deviceName={state.deviceName}
        startDate={state.startDate}
        startTime={state.startTime}
        endDate={state.endDate}
        endTime={state.endTime}
        handleChange={handleChange}
      />
      <PageTitle
        title="Halaman Statistik"
        description="Dapatkan statistik sensor data pada masing-masing perangkat"
        icon={
          <BarChartIcon fill="#fafafa" className="w-12 lg:w-16 h-12 lg:h-16" />
        }
      />

      <br />
      <div className="flex justify-end items-center space-x-3 no-print">
        <button
          className="flex items-center focus:outline-none text-primary"
          onClick={() => setModal(true)}
        >
          <FilterIcon className="fill-current w-5 h-5" />
          <div className="ml-1">Filter Data</div>
        </button>
        {isDone && (
          <button
            className="flex items-center focus:outline-none text-primary"
            onClick={() => window.print()}
          >
            <PrintIcon className="fill-current w-5 h-5" />
            <div className="ml-1">Cetak</div>
          </button>
        )}
      </div>

      <div className="mt-3">
        <div className="border border-primary rounded-xl px-3 py-2">
          {!isDone ? (
            <p>
              Silakan klik{" "}
              <span
                className="text-primary underline cursor-pointer"
                onClick={() => setModal(true)}
              >
                di sini{" "}
              </span>{" "}
              untuk membuat statistik deskripsi.
            </p>
          ) : (
            <div className="flex items-center space-x-2">
              <div>
                <BoardIcon className="w-12 lg:w-16 h-12 lg:h-16 fill-current text-primary" />
              </div>
              <div>
                <div className="text-xl">{state.deviceName}</div>
                <p className="text-sm">{`Range : ${getDateFormat2(
                  `${state.startDate} ${state.startTime}`
                )} - ${getDateFormat2(
                  `${state.endDate} ${state.endTime}`
                )}`}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {isDone && (
        <CardDashboard title="Result" className="mt-8">
          <div>
            <div className="mt-3 font-semibold">1. Visualisasi Data</div>
            <div className="grid grid-cols-12">
              {CONFIG.variable.map((obj, idx) => (
                <div
                  className="col-span-12 lg:col-span-6"
                  key={"chart-" + obj.key}
                >
                  <div className="">
                    &bull;{" "}
                    {`${obj.label} ${obj.unit ? "(" + obj.unit + ")" : ""}`}
                  </div>
                  <div className="h-44 text-xs p-2">
                    <ChartLine
                      data={payload}
                      dataKeyY={`payload.${obj.key}`}
                      stroke={GRAPH_COLOR[idx]}
                      dataKeyX="timestamp"
                      xAxisLabel="time"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <div className="mt-3 font-semibold">2. Statistik</div>
            <div className="w-full overflow-x-auto px-0.5">
              <table className="w-full mt-2 text-sm">
                <thead>
                  <tr>
                    <th className="bg-primary text-white px-1 py-1.5">Item</th>
                    {CONFIG.variable.map((obj) => (
                      <th
                        key={"thead" + obj.key}
                        className="bg-primary text-white px-1 py-1.5"
                      >
                        {obj.key}{" "}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {statistics.map((statistic, idx) => (
                    <tr
                      key={"row-stat" + idx}
                      className={
                        idx % 2 === 0 ? "bg-primary bg-opacity-20" : ""
                      }
                    >
                      <td className="px-1 py-1.5">{statistic.label}</td>
                      {CONFIG.variable.map((obj) => (
                        <td
                          key={statistic.label + obj.key}
                          className="px-1 py-1.5"
                        >
                          {`${
                            result[obj.key] && result[obj.key][statistic.label]
                              ? result[obj.key][statistic.label]
                              : "-"
                          }`}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
            </div>
          </div>
        </CardDashboard>
      )}
    </div>
  );
};

export default Statistic;
