import { ReactComponent as Close } from "../../../../assets/img/icon/close_black_24dp.svg";
import logo from "../../../../assets/img/logo/xirka-icon.png";
import { COLOR } from "../../../../configs/constant";

const Modal = (props) => {
  const handleClose = () => {
    if (props.handleClose) props.handleClose();
  };
  const handleSubmit = () => {
    if (props.handleSubmit) props.handleSubmit();
  };
  return (
    <div
      className={
        "fixed top-0 left-0 right-0 min-h-screen bg-gray-900 bg-opacity-40 px-2 text-sm sm:text-base " +
        (props.show ? "opacity-100" : "opacity-30")
      }
      style={{ zIndex: props.show ? "20" : "-10" }}
    >
      <div
        className={
          "max-w-3xl bg-white shadow-lg mx-auto  px-3 md:px-6 py-3 md:py-6 rounded-xl relative duration-100 ease-in " +
          (props.show ? "opacity-100 mt-20" : "opacity-30 mt-10")
        }
      >
        <button
          className="bg-red-200 focus:outline-none shadow rounded-full absolute top-0 right-0 mt-3 md:mt-4 mr-3 md:mr-4 lg:-mt-6 lg:-mr-6"
          onClick={handleClose}
        >
          <Close fill={COLOR.WARNING} className="w-4 md:w-5 h-4 md:h-5" />
        </button>

        <div className="flex items-center space-x-2">
          <img src={logo} alt="" className="h-5" />
          <div>
            <span className="text-red-600 uppercase">Xirka</span> IoT Platform
          </div>
        </div>
        <div className="mt-3">{props.children}</div>
        <div className="mt-6 flex space-x-2 justify-end">
          <button
            className="w-20 px-2 py-0.5 bg-warning rounded text-white focus:outline-none focus:ring-2"
            onClick={handleClose}
          >
            Batal
          </button>
          <button
            className="px-2 py-0.5 bg-secondary rounded text-white focus:outline-none focus:ring-2"
            onClick={handleSubmit}
          >
            {props.submitLabel ? props.submitLabel : "Simpan"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
