import { useContext } from "react";
import { Link } from "react-router-dom";
import avatar from "../../../../assets/img/avatar/default-avatar.png";
import { GlobalContext } from "../../../contexts/globalContext";

const navigations = [
  // { label: "About Application" },
  // { label: "Peta Situs" },
  { label: "FAQ", path: "/dashboard" },
  { label: "Tentang Xirka", path: "/dashboard/about" },
];

const AsideDashboard = () => {
  const [sharedState, dispatch] = useContext(GlobalContext);
  return (
    <aside
      className="no-print w-full xl:w-80 px-1 md:px-3 pt-6 pb-40 xl:pb-16 flex-shrink-0 border-t xl:border-t-0 xl:border-r-2 relative"
      style={{ minHeight: "calc(100vh - 50px)" }}
    >
      {/* USER INFORMATION */}
      <div>
        <div className="mt-8 flex justify-center xl:justify-start space-x-6 items-center">
          <div
            className="w-20 h-20 rounded-full bg-gray-500 bg-cover bg-center"
            style={{ backgroundImage: `url("${avatar}")` }}
          />
          <div className="leading-none">
            <div className="text-lg font-semibold text-gray-800">
              {sharedState.user.name}
            </div>
            <div className="-mt-0.5 text-gray-600 text-opacity-50 text-sm">
              {sharedState.user.email}
            </div>
          </div>
        </div>

        <p className="mt-5 text-center xl:text-left">{`@${sharedState.user.name} is an awesome smart meter admin.`}</p>
      </div>

      <hr className="mt-6 " />
      {/* NAVIGATION */}
      <div className="mt-6 text-center xl:text-left">
        <div className="font-semibold uppercase text-sm text-gray-800">
          Navigasi
        </div>
        <ul className="list-inside list-disc mt-1">
          {navigations.map((navigation) => (
            <li className="mt-0.5" key={"aside-" + navigation.label}>
              <Link to={navigation.path}>
                <span className="cursor-pointer">{navigation.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {/* APP INFO */}
      <div className="mt-6 text-center xl:text-left">
        <div className="font-semibold uppercase text-sm text-gray-800">
          Aplikasi
        </div>
        <p>
          Aplikasi Internet of Things (IoT) yang terintegrasi dengan perangkat
          pintar untuk memantau data sensor dan kendali perangkat.
        </p>

        <div className="text-sm absolute mb-8 xl:mb-0 bottom-10 xl:bottom-2 w-full">
          <div className="text-center">
            <p>Copyright &copy; 2021</p>
            <p>Xirka Silicon Technology</p>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default AsideDashboard;
