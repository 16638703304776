import { useContext, useState } from "react";
import { patchUserPassword } from "../../../../services/httpServices/userService";
import { GlobalContext } from "../../../contexts/globalContext";
import Modal from "../../atoms/Modal/Modal";

const ModalProfileChangePassword = (props) => {
  const [sharedState, dispatch] = useContext(GlobalContext);
  const [state, setState] = useState({
    prevPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (
        state.prevPassword === "" ||
        state.newPassword === "" ||
        state.passwordConfirmation === ""
      )
        throw new Error("Kamu harus mengisi form terlebih dulu");
      if (state.newPassword !== state.passwordConfirmation)
        throw new Error(
          '"Passsword Baru" and "Konfirmasi password" tidak cocok'
        );
      dispatch("isLoading", true);

      if (state.newPassword.length < 8)
        throw new Error('"Password baru" minimal 8 karakter"');

      await patchUserPassword({
        prevPassword: state.prevPassword,
        newPassword: state.newPassword,
      });

      dispatch("isLoading", false);
      props.handleClose();
    } catch (error) {
      dispatch("isLoading", false);
      dispatch("alert", {
        message: error.message,
        type: "warning",
        show: true,
      });
    }
  };

  return (
    <Modal
      show={props.show}
      handleClose={props.handleClose}
      handleSubmit={handleSubmit}
    >
      <div className="mt-3 md:mt-5 grid grid-cols-12 items-center">
        <div className="col-span-12 sm:col-span-3">Password </div>

        <input
          type="password"
          className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
          name="prevPassword"
          value={state.prevPassword}
          onChange={handleChange}
        />
      </div>

      <div className="mt-3 md:mt-5 grid grid-cols-12 items-center">
        <div className="col-span-12 sm:col-span-3">Password Baru </div>

        <input
          type="password"
          className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
          name="newPassword"
          value={state.newPassword}
          onChange={handleChange}
        />
      </div>

      <div className="mt-3 md:mt-5 grid grid-cols-12 items-center">
        <div className="col-span-12 sm:col-span-3">Konfirmasi Password</div>

        <input
          type="password"
          className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
          name="passwordConfirmation"
          value={state.passwordConfirmation}
          onChange={handleChange}
        />
      </div>
    </Modal>
  );
};

export default ModalProfileChangePassword;
