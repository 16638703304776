import ToastifyService from "../toastifyService/ToastifyService";
import HistoryService from "../httpServices/historyService";


class SensorService {
    static async saveToLocal(name, value) {
        await HistoryService.addHistory({
            name: name,
            message: value
        })
        // let getValue = localStorage.getItem(`notify_${name}`);
        // if(getValue) {
        //     getValue = JSON.parse(getValue);
        //     getValue.push({
        //         name: name,
        //         message: value,
        //         timestamp: Math.floor(new Date() / 1)
        //     });
        //     if(getValue.length > 3000) getValue.shift();
        //     return localStorage.setItem(`notify_${name}`, JSON.stringify(getValue));
        // }
        // getValue = [{
        //     name: name,
        //     message: value,
        //     timestamp: Math.floor(new Date() / 1)
        // }];
        // localStorage.setItem(`notify_${name}`, JSON.stringify(getValue));
    }

    static async cekSensor(payload, name) {
        const temp = payload["WT"];
        const oksigen = payload["O2"];
        const ph = payload["PH"];
        const tds = payload["TDS"];
        if(temp && temp < 26) {
            ToastifyService.run("error", `Danger condition Temperature low`);
            await this.saveToLocal(name, `Danger condition Temperature low`);
        }
        if(temp && temp > 32) {
            ToastifyService.run("error", `Danger condition Temperature high`);
            await this.saveToLocal(name, `Danger condition Temperature high`);
        }
        if(temp && temp < 32 && temp > 31) {
            ToastifyService.run("warning", `Warning Range Temperature is dangerous condition`);
            await this.saveToLocal(name, `Warning Range Temperature is dangerous condition`);
        }
        if(ph && ph < 7.6) {
            ToastifyService.run("error", `Danger condition PH low`);
            await this.saveToLocal(name, `Danger condition PH low`);
        }
        if(ph && ph > 8.3) {
            ToastifyService.run("error", `Danger condition PH high`);
            await this.saveToLocal(name, `Danger condition PH high`);
        }
        if(ph && ph < 8.3 && ph > 8.2) {
            ToastifyService.run("warning", `Warning Range PH is dangerous condition`);
            await this.saveToLocal(name, `Warning Range PH is dangerous condition`);
        }
        if(oksigen && (oksigen < 4 || oksigen > 8)) {
            ToastifyService.run("warning", `Warning Range PH is dangerous condition`);
            await this.saveToLocal(name, `Warning Range PH is dangerous condition`);
        }
        if(oksigen && ((oksigen > 4 && oksigen < 5) || (oksigen < 8 && oksigen > 7.5))) {
            ToastifyService.run("error", `Oxygen is Danger condition`);
            await this.saveToLocal(name, `Oxygen is Danger condition`);
        }
        if(tds && tds > 35) {
            ToastifyService.run("error", `Danger condition TDS high`);
            await this.saveToLocal(name, `Danger condition TDS high`);
        }
        if(tds && tds < 20) {
            ToastifyService.run("error", `Danger condition TDS low`);
            await this.saveToLocal(name, `Danger condition TDS low`);
        }
        if(tds && ((tds > 20 && tds < 21) || (tds < 35 & tds > 35))) {
            ToastifyService.run("warning", `Danger Range TDS is dangerous condition`);
            await this.saveToLocal(name, `Danger Range TDS is dangerous condition`);
        }
    }
}

export default SensorService;