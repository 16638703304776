export const COLOR = {
  WHITESMOKE: "#E5E5E5",
  DARK: "#6b7280",
  PRIMARY: "#7F1D1D",
  SECONDARY: "#5CA0D3",
  WARNING: "#e3342f",
  SUCCESS: "#16c79a",
  INFO: "#FF7E67",
};

export const GRAPH_COLOR = [
  "#f05454",
  "#16c79a",
  "#8884d8",
  "#00bcd4",
  "#845ec2",
];

export const CONFIG = {
  appName: "sensorair",
  root: ["sensoraircommand"],
  baseLocation: [0.3341564430192587, 100.84709752163386],
  baseImgUrl: `${process.env.REACT_APP_BACKEND_URI}/public/img`,
  variable: [
    { key: "WT", label: "Temperatur Air", unit: "c" },
    { key: "O2", label: "Kadar Oksigen Terlarut", unit: "mg/L" },
    { key: "TRB", label: "Turbidity", unit: "" },
    { key: "PH", label: "Kadar Keasaman Air", unit: "pH" },
    { key: "AT", label: "Temperatur Udara", unit: "c" },
    { key: "AH", label: "Kelembaban Udara", unit: "%" },
    { key: "VB", label: "Tegangan Baterai", unit: "Volt" },
    { key: "VSC", label: "Tegangan Sel Surya", unit: "Volt" },
    { key: "TDS", label: "TDS", unit: "" },
  ],
  downloadLimit: 1000,
  lifeTimeLimit: 60,
  commandList: [
    { label: "Turn On", key: "turn on" },
    { label: "Turn Off", key: "turn off" },
  ],
};
