import { useState } from "react";
import { CONFIG } from "../../../../configs/constant";
import { client } from "../../../../services/mqttServices/mqttService";
import Modal from "../../atoms/Modal/Modal";

const ModalDeviceRemote = (props) => {
  const [state, setState] = useState({
    command: CONFIG.commandList[1].key,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = () => {
    const message = {
      device: props.deviceName,
      cmd: state.command,
    };
    client.publish(`${props.deviceName}/cmd`, JSON.stringify(message), {
      qos: 2,
    });
    props.handleClose();
  };
  return (
    <Modal
      show={props.show}
      handleClose={props.handleClose}
      handleSubmit={handleSubmit}
      submitLabel="Control"
    >
      <div className="mt-3 md:mt-5 grid grid-cols-12 items-center">
        <div className="col-span-12 sm:col-span-3">Nama Perangkat </div>

        <input
          type="text"
          className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
          name="deviceName"
          value={props.deviceName}
          onChange={handleChange}
          disabled={true}
        />
      </div>

      <div className="mt-3 md:mt-5 grid grid-cols-12 items-center">
        <div className="col-span-12 sm:col-span-3">Perintah </div>
        <select
          name="command"
          className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
          value={state.command}
          onChange={handleChange}
        >
          {CONFIG.commandList.map((command) => (
            <option key={"option" + command.key} value={command.key}>
              {command.label}
            </option>
          ))}
        </select>
      </div>
    </Modal>
  );
};

export default ModalDeviceRemote;
