import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ReactComponent as ChevronRight } from "../../../../../assets/img/icon/chevron_right_black_24dp.svg";
import { ReactComponent as Board } from "../../../../../assets/img/icon/developer_board_black_24dp.svg";
import { ReactComponent as ListIcon } from "../../../../../assets/img/icon/format_list_bulleted_black_24dp.svg";
import { ReactComponent as MonitorTv } from "../../../../../assets/img/icon/tv_black_24dp.svg";
import { COLOR, CONFIG } from "../../../../../configs/constant";
import {
  deleteDevice,
  getDeviceDetail,
} from "../../../../../services/httpServices/deviceService";
import { GlobalContext } from "../../../../contexts/globalContext";
import CardDashboard from "../../../../ui/atoms/CardDashboard/CardDashboard";
import PageTitle from "../../../../ui/atoms/PageTitle/PageTitle";
import ModalDeviceEdit from "../../../../ui/molecules/ModalDeviceEdit/ModalDeviceEdit";
import ModalDeviceRemote from "../../../../ui/molecules/ModalDeviceRemote/ModalDeviceRemote";
import DeviceMonitor from "../../../../ui/organisms/DeviceDetail/DeviceMonitor/DeviceMonitor";
import DeviceSensorData from "../../../../ui/organisms/DeviceDetail/DeviceSensorData/DeviceSensorData";
import DeviceStatistic from "../../../../ui/organisms/DeviceDetail/DeviceStatistic/DeviceStatistic";
import ModalHistoryNotify from "../../../../ui/molecules/ModalHistoriNotify/ModalHistoryNotify";

const subMenus = [
  {
    name: "monitor",
    label: "monitor",
    icon: <MonitorTv fill="#fafafa" className="w-6 h-6" />,
  },
  {
    name: "sensor data",
    label: "data sensor",
    icon: <ListIcon fill="#fafafa" className="w-6 h-6" />,
  },
  // {
  //   label: "statistic",
  //   icon: <BarChartIcon fill="#fafafa" className="w-6 h-6" />,
  // },
];
const DeviceDetail = () => {
  const [sharedState, dispatch] = useContext(GlobalContext);
  const params = useParams();
  const history = useHistory();
  const [active, setActive] = useState("monitor");
  const [action, setAction] = useState("edit");
  const [device, setDevice] = useState({});
  const [modal, setModal] = useState(null);

  const handleDeleteDevice = async () => {
    try {
      dispatch("isLoading", true);
      dispatch("confirm", { show: false, message: "", callback: null });
      await deleteDevice({
        deviceName: params.deviceName,
        application: CONFIG.appName,
      });
      history.push("/dashboard/device");
      dispatch("isLoading", false);
    } catch (error) {
      dispatch("alert", {
        show: true,
        message: error.message,
        type: "warning",
      });
      dispatch("isLoading", false);
    }
  };

  const handleAction = () => {
    switch (action) {
      case "edit":
        setModal("edit");
        break;
      case "delete":
        dispatch("confirm", {
          show: true,
          message: `Menghapus perangkat "${params.deviceName}" juga akan menghapus data sensornya. Lanjutkan hapus perangkat "${params.deviceName}"?`,
          callback: handleDeleteDevice,
        });
        break;
      case "remote":
        setModal("remote");
        break;
      case "history":
        setModal("history");
        break;

      default:
        dispatch("alert", {
          show: true,
          message: "oops there is something wrong",
          type: "warning",
        });
        break;
    }
  };
  const handleGetDevice = async () => {
    try {
      const { result } = await getDeviceDetail(
        `${params.deviceName}?application=${CONFIG.appName}`
      );
      if (result.description && result.description.img)
        result.description.img = `${CONFIG.baseImgUrl}/${result.description.img}`;

      setDevice(result);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handleGetDevice();
  }, []);

  let content = null;
  switch (active) {
    case "monitor":
      content = <DeviceMonitor />;
      break;
    case "sensor data":
      content = <DeviceSensorData />;
      break;
    case "statistic":
      content = <DeviceStatistic />;
      break;

    default:
      break;
  }

  return (
    <div className="mt-2 lg:mt-6">
      <PageTitle
        title="Halaman Perangkat"
        description="Dapatkan informasi tentang perangkat smartmeter meliputi distribusi serta data sensornya."
        icon={<Board fill="#fafafa" className="w-12 lg:w-16 h-12 lg:h-16" />}
      />

      <ModalDeviceRemote
        show={modal === "remote"}
        handleClose={() => setModal(null)}
        deviceName={params.deviceName}
      />
      <ModalDeviceEdit
        show={modal === "edit"}
        setPosition={(updatedPosition) =>
          setDevice((prevState) => ({
            ...prevState,
            position: updatedPosition,
          }))
        }
        handleClose={() => setModal(null)}
        deviceName={params.deviceName}
        position={
          device.position && device.position.lat
            ? [device.position.lat, device.position.lng]
            : []
        }
      />
      <ModalHistoryNotify
          show={modal === "history"}
          handleClose={() => setModal(null)}
          deviceName={params.deviceName}
      />

      <br />
      <div className="mt-6">
        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-3 lg:space-x-5">
          <div className="w-full lg:w-64 flex-shrink-0">
            <CardDashboard
              title="Informasi Perangkat"
              icon={<Board fill="#fafafa" className="w-6 h-6" />}
              className="col-span-12 pt-5 pb-4"
            >
              <div className="mt-2">
                <div>
                  <img
                    src={device.description ? device.description.img : ""}
                    alt=""
                    className="w-full object-fit rounded"
                    style={{ maxHeight: "200px" }}
                  />
                </div>
              </div>

              <div className="mt-1">
                <div className="text-sm text-gray-700 text-opacity-50">
                  Perangkat
                </div>
                <div>{params.deviceName}</div>
              </div>
              <div className="mt-1">
                <div className="text-sm text-gray-700 text-opacity-50">
                  Penempatan
                </div>
                <div>{device.description ? device.description.label : "-"}</div>
              </div>
              <div className="mt-1">
                <div className="text-sm text-gray-700 text-opacity-50">
                  Deskripsi
                </div>
                <div>
                  {device.description ? device.description.detail : "-"}
                </div>
              </div>
              <div className="mt-1">
                <div className="text-sm text-gray-700 text-opacity-50">
                  Lokasi (Lat, Lng)
                </div>
                <div>
                  {device.position && device.position.lat && device.position.lng
                    ? `${Math.round(device.position.lat * 100) / 100}, ${
                        Math.round(device.position.lng * 100) / 100
                      }`
                    : "-"}
                </div>
              </div>
              {/* <div className="mt-1">
                <div className="text-sm text-gray-700 text-opacity-50">
                  Short Description
                </div>
                <div>{device.description || "-"}</div>
              </div> */}
              <div className="mt-1">
                <div className="text-sm text-gray-700 text-opacity-50">
                  Aksi
                </div>
                <div className="flex text-sm">
                  <select
                    name="action"
                    className="w-full border border-primary rounded-l-md px-1 py-1 focus:outline-none"
                    value={action}
                    onChange={(e) => {
                      setAction(e.target.value);
                    }}
                  >
                    <option value="edit"> Ubah Lokasi </option>
                    {/* <option value="remote"> Remote </option> */}
                    <option value="delete"> Hapus Perangkat </option>
                    <option value="history"> Lihat History Notifikasi </option>
                  </select>
                  <button
                    className="bg-primary text-white px-1 py-1 rounded-r-md focus:outline-none"
                    onClick={handleAction}
                  >
                    Jalankan
                  </button>
                </div>
              </div>
            </CardDashboard>
          </div>
          <div className="mt-5 w-full relative pt-8 sm:pt-0">
            <div className="absolute top-0.5 right-0">
              <Link to="/dashboard/device">
                <div className="flex space-x-0.5 items-center cursor-pointer">
                  <div>Kembali</div>
                  <ChevronRight fill={COLOR.DARK} className="w-6 h-6" />
                </div>
              </Link>
            </div>
            <div className="flex space-x-3 w-full overflow-x-auto">
              {subMenus.map((submenu) => (
                <div
                  key={"submenu-" + submenu.name}
                  className={
                    "flex-shrink-0 cursor-pointer px-3 py-0.5 bg-primary  text-white rounded-lg  flex items-center space-x-1 " +
                    (active === submenu.name
                      ? ""
                      : "bg-opacity-30 hover:bg-opacity-50")
                  }
                  onClick={() => setActive(submenu.name)}
                >
                  {submenu.icon}
                  <div className="capitalize">{submenu.label}</div>
                </div>
              ))}
            </div>

            <div className="mt-3 border border-primary shadow-lg rounded-lg px-2 md:px-4 py-3">
              {content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceDetail;
