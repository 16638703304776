import { useContext, useState } from "react";
import { CONFIG } from "../../../../configs/constant";
import { postDevice } from "../../../../services/httpServices/deviceService";
import { GlobalContext } from "../../../contexts/globalContext";
import Modal from "../../atoms/Modal/Modal";

const ModalDeviceAdd = (props) => {
  const [state, setState] = useState({
    deviceName: "",
  });
  const [sharedState, dispatch] = useContext(GlobalContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = async () => {
    try {
      if (state.deviceName === "")
        throw new Error("Nama Perangkat tidak boleh kosong");
      dispatch("isLoading", true);
      await postDevice({
        deviceName: state.deviceName,
        application: CONFIG.appName,
      });
      dispatch("isLoading", false);
      props.handleUpdate();
    } catch (error) {
      dispatch("isLoading", false);
      dispatch("alert", {
        message: error.message,
        type: "warning",
        show: true,
      });
    }
  };
  return (
    <Modal
      show={props.show}
      handleClose={props.handleClose}
      handleSubmit={handleSubmit}
      submitLabel="Tambah Perangkat"
    >
      <div className="mt-3 md:mt-5 grid grid-cols-12 items-center">
        <div className="col-span-12 sm:col-span-3">Nama Aplikasi </div>

        <div className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none ">
          {CONFIG.appName}
        </div>
      </div>

      <div className="mt-3 md:mt-5 grid grid-cols-12 items-center">
        <div className="col-span-12 sm:col-span-3">Nama Perangkat </div>

        <input
          type="text"
          className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
          name="deviceName"
          value={state.deviceName}
          onChange={handleChange}
        />
      </div>
    </Modal>
  );
};

export default ModalDeviceAdd;
