import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Lock } from "../../../assets/img/icon/lock_black_24dp.svg";
import { ReactComponent as Mail } from "../../../assets/img/icon/mail_black_24dp.svg";
import { ReactComponent as Person } from "../../../assets/img/icon/person_black_24dp.svg";
import { ReactComponent as Visibility } from "../../../assets/img/icon/visibility_black_24dp.svg";
import { ReactComponent as VisibilityOff } from "../../../assets/img/icon/visibility_off_black_24dp.svg";
import logo from "../../../assets/img/logo/xirka-icon.png";
import { postAuth } from "../../../services/httpServices/authService";
import { postPasswordForget } from "../../../services/httpServices/userService";
import { GlobalContext } from "../../contexts/globalContext";
import BasicTemplate from "../../templates/BasicTemplates/BasicTemplates";
import ButtonLg from "../../ui/atoms/ButtonLg/ButtonLg";
import "./Login.css";
const Login = (props) => {
  const isMounted = useRef(true);
  const [sharedState, dispatch] = useContext(GlobalContext);
  const history = useHistory();
  const [state, setState] = useState({
    username: "",
    password: "",
    email: "",
    rememberMe: true,
    passwordHidden: true,
    isForgot: false,
  });
  const [hidden, setHidden] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleToggleRemember = (e) => {
    setState((prevState) => ({
      ...prevState,
      rememberMe: !prevState.rememberMe,
    }));
  };

  const handleSubmitLogin = async () => {
    try {
      setHidden(true);
      dispatch("isLoading", true);
      if (state.username === "")
        throw new Error(`"username" tidak boleh kosong`);
      if (state.password === "")
        throw new Error(`"password" tidak boleh kosong`);
      if (state.username !== process.env.REACT_APP_USERNAME)
        throw new Error('"username" tidak dikenali');
      const resp = await postAuth({
        username: state.username,
        password: state.password,
      });

      dispatch("user", { token: resp.token });
      sessionStorage.setItem(
        "iotPlatformApp",
        JSON.stringify({ token: resp.token })
      );
      dispatch("isLoading", false);
      history.push("/dashboard");
    } catch (error) {
      let message;
      // console.log(error.message);
      switch (error.message) {
        case "invalid password":
          message = '"password" tidak valid';
          break;
        default:
          message = error.message;
          break;
      }
      // if (!isMounted.current) return null;
      dispatch("alert", {
        show: true,
        message: message,
        type: "warning",
      });
      dispatch("isLoading", false);
    }
  };

  const handleSendEmail = async () => {
    try {
      dispatch("isLoading", true);
      if (state.username !== process.env.REACT_APP_USERNAME)
        throw new Error('"email" tidak dikenali');

      await postPasswordForget({
        email: state.username,
      });
      dispatch("alert", {
        show: true,
        type: "success",
        message: "kami telah mengirimkan email untuk mengatur ulang password.",
      });
      dispatch("isLoading", false);
    } catch (error) {
      dispatch("alert", {
        show: true,
        message: error.message,
        type: "warning",
      });
      dispatch("isLoading", false);
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <BasicTemplate>
      <div className="h-screen relative font-light bg-gradient-to-tr from-royal-dark to-red-400">
        <div className="h-full xl:container mx-auto px-2 sm:px-6 md:px-8 lg:px-10">
          <div className="bg-white overflow-hidden sm:rounded-xl shadow-xl absolute w-full h-full sm:w-2/3 lg:w-3/5 sm:h-4/5 xl:w-3/4 xl:h-3/4 top-0 left-0 right-0 bottom-0 m-auto">
            <div className="flex-col-reverse xl:flex xl:flex-row-reverse h-full">
              <div className="hidden xl:block xl:h-full w-full xl:w-full xl:px-12 xl:text-white xl:bg-gradient-to-tr xl:from-royal-dark xl:to-gray-900">
                <div className="mt-12 xl:mt-48">
                  <img src={logo} alt="" className="mx-auto" />
                </div>
                <div className="text-3xl font-semibold text-center mt-2">
                  <span className="text-red-400">XIRKA</span> IoT Platform
                </div>
                <div className="text-center mt-5">
                  Selamat datang di platform The Xirka IoT. Tempat yang baik
                  untuk membuat aplikasi berbasis IoT. Dengan hanya beberapa
                  langkah, Anda dapat memulai proyek mengagumkan Anda.
                </div>
              </div>

              <div className="w-full h-full xl:h-auto xl:w-full px-6 md:px-12 xl:px-12 py-3 relative login-container-left">
                <div className="mt-10 xl:mt-0">
                  <p className="text-sm md:text-base">Selamat Datang :)</p>
                  <h1 className="text-xl md:text-3xl">
                    <span className="text-red-400 font-semibold">Xirka</span>{" "}
                    IoT Platform
                  </h1>
                </div>
                {state.isForgot ? (
                  <>
                    <p className="mt-6 md:mt-8 text-sm md:text-base">
                      Lupa kata sandi Anda? Silakan masukkan email Anda dan kami
                      akan mengirimkan tautan untuk mereset kata sandi.
                    </p>

                    <div className="mt-4 mb-56">
                      <div className="mt-2">
                        <div className="text-sm text-red-900 text-opacity-70">
                          Email
                        </div>
                        <div className="relative">
                          <Mail
                            className="absolute top-1.5 left-2"
                            fill={"#7F1D1D60"}
                          />
                          <input
                            type="text"
                            className={
                              "px-3 py-1.5 rounded-lg w-full pl-10 text-red-900 text-opacity-60 bg-red-900 bg-opacity-5 focus:bg-opacity-50 focus:bg-red-50 focus:outline-none border border-red-900 border-opacity-40 focus:border-opacity-70 placeholder-red-900 placeholder-opacity-20 font-light"
                            }
                            name="username"
                            autoComplete="off"
                            value={state.username}
                            onChange={handleChange}
                            //   disabled={loading}
                          />
                        </div>
                      </div>

                      <div className="flex justify-end text-sm mt-3">
                        <div
                          className="text-red-900 hover:text-red-400 cursor-pointer"
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              isForgot: false,
                            }))
                          }
                        >
                          Kembali ke Login
                        </div>
                      </div>
                      <ButtonLg
                        className="mt-8"
                        label="Kirim Email"
                        onClick={handleSendEmail}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <p className="mt-6 md:mt-8 text-sm md:text-base">
                      Silakan login untuk mengakses aplikasi konsol
                    </p>

                    <div className="mt-4">
                      <div className="mt-2">
                        <div className="text-sm text-red-900 text-opacity-70">
                          Username
                        </div>
                        <div className="relative">
                          <Person
                            className="absolute top-1.5 left-2"
                            fill={"#7F1D1D60"}
                          />
                          <input
                            type="text"
                            className={
                              "px-3 py-1.5 rounded-lg w-full pl-10 text-red-900 text-opacity-60 bg-red-900 bg-opacity-5 focus:bg-opacity-50 focus:bg-red-50 focus:outline-none border border-red-900 border-opacity-40 focus:border-opacity-70 placeholder-red-900 placeholder-opacity-20 font-light"
                            }
                            name="username"
                            autoComplete="off"
                            value={state.username}
                            onChange={handleChange}
                            //   disabled={loading}
                          />
                        </div>
                      </div>

                      <div className="mt-2">
                        <div className="text-sm text-red-900 text-opacity-70">
                          Password
                        </div>
                        <div className="relative">
                          <Lock
                            className="absolute top-1.5 left-2"
                            fill={"#7F1D1D60"}
                          />
                          {hidden ? (
                            <VisibilityOff
                              className="cursor-pointer absolute top-1.5 right-2"
                              fill={"#7F1D1D60"}
                              onClick={() => {
                                //   if (loading) return;
                                setHidden(false);
                              }}
                            />
                          ) : (
                            <Visibility
                              className="cursor-pointer absolute top-1.5 right-2"
                              fill={"#7F1D1D60"}
                              onClick={() => {
                                //   if (loading) return;
                                setHidden(true);
                              }}
                            />
                          )}
                          <input
                            type={hidden ? "password" : "text"}
                            className={
                              "px-3 py-1.5 rounded-lg w-full pl-10 text-red-900 text-opacity-60 bg-red-900 bg-opacity-5 focus:bg-opacity-50 focus:bg-red-50 focus:outline-none border border-red-900 border-opacity-40 focus:border-opacity-70 placeholder-red-900 placeholder-opacity-20 font-light"
                            }
                            name="password"
                            value={state.password}
                            onChange={handleChange}
                            //   disabled={loading}
                          />
                        </div>
                      </div>

                      <div className="flex justify-between text-sm mt-3">
                        <div>
                          <input
                            type="checkbox"
                            id="rememberMe"
                            name="rememberMe"
                            // value={}
                            checked={state.rememberMe}
                            onChange={handleToggleRemember}
                          />
                          <label htmlFor="rememberMe"> Ingatkan Saya</label>
                        </div>

                        <div
                          className="text-red-900 hover:text-red-400 cursor-pointer"
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              isForgot: true,
                            }))
                          }
                        >
                          Lupa Password
                        </div>
                      </div>
                      <ButtonLg
                        label="Login"
                        onClick={handleSubmitLogin}
                        className="mt-8"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicTemplate>
  );
};

export default Login;
