import { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router";
import About from "../../pages/Dashboard/About/About";
import DashboardMain from "../../pages/Dashboard/DashboardMain/DashboardMain";
import DeviceDetail from "../../pages/Dashboard/Device/DeviceDetail/DeviceDetail";
import DeviceList from "../../pages/Dashboard/Device/DeviceList/DeviceList";
import Profile from "../../pages/Dashboard/Profile/Profile";
import Statistic from "../../pages/Dashboard/Statistic/Statistic";
import DashboardTemplate from "../../templates/DashboardTemplate/DashboardTemplate";

const PAGE_LIST = [
  {
    label: "Dasbor",
    path: "/dashboard",
  },
  {
    label: "Perangkat",
    path: "/dashboard/device",
  },
  {
    label: "Data Sensor",
    path: "/dashboard/payload",
  },
  {
    label: "Statistik",
    path: "/dashboard/statistic",
  },
  {
    label: "Profil",
    path: "/dashboard/profile",
  },
];

const DashboardRoute = () => {
  const [active, setActive] = useState("Dashboard");
  const location = useLocation();

  useEffect(() => {
    let tempActive;
    PAGE_LIST.forEach((page) => {
      if (location.pathname.includes(page.path)) {
        tempActive = page.label;
      }
    });
    if (tempActive !== active) setActive(tempActive);
  }, [location.pathname]);
  return (
    <DashboardTemplate active={active}>
      <Switch>
        <Route path="/dashboard/device/:deviceName" component={DeviceDetail} />
        <Route path="/dashboard/device" component={DeviceList} />
        <Route path="/dashboard/profile" component={Profile} />
        <Route path="/dashboard/statistic" component={Statistic} />
        <Route path="/dashboard/about" component={About} />
        <Route path="/dashboard" component={DashboardMain} />
      </Switch>
    </DashboardTemplate>
  );
};

export default DashboardRoute;
