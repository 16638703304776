const CardDashboard = (props) => {
  return (
    <div
      className={`relative mt-3 border border-primary shadow-lg rounded-lg px-2 sm:px-4 py-2 sm:py-3 text-sm  sm:text-base ${props.className}`}
    >
      <div className="absolute -top-3 bg-primary text-white px-2 py-0.5 rounded-lg">
        <div className="flex items-center space-x-1">
          <div>{props.icon}</div>
          <div>{props.title}</div>
        </div>
      </div>
      <div className="mt-1.5">{props.children}</div>
    </div>
  );
};

export default CardDashboard;
