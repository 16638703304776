import { useContext } from "react";
import { COLOR } from "../../../configs/constant";
import { GlobalContext } from "../../contexts/globalContext";
import Alert from "../../ui/atoms/Alert/Alert";
import Confirm from "../../ui/atoms/Confirm/Confirm";
import Loader from "../../ui/atoms/Loader/Loader";

const BasicTemplate = (props) => {
  const [sharedState, dispatch] = useContext(GlobalContext);
  const hideAlert = () => {
    dispatch("alert", { show: false });
  };

  const hideConfirm = () => {
    dispatch("confirm", { show: false });
  };

  return (
    <div
      className="w-full min-h-screen relative"
      style={{ color: COLOR.DARK, background: COLOR.WHITESMOKE }}
    >
      {sharedState.isLoading && <Loader />}
      <Alert data={sharedState.alert} handleClose={hideAlert} />
      <Confirm data={sharedState.confirm} handleClose={hideConfirm} />
      <section>{props.children}</section>
    </div>
  );
};

export default BasicTemplate;
