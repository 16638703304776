import FileDownload from "js-file-download";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { CONFIG } from "../../../../../configs/constant";
import {
  getDateFormat2,
  getDateInputFormat,
  getDateTimeFormat,
} from "../../../../../helpers/helper";
import {
  getPayload,
  postDownloadPayload,
} from "../../../../../services/httpServices/payloadService";
import { GlobalContext } from "../../../../contexts/globalContext";
import ModalPayloadFilter from "../../../molecules/ModalPayloadFilter/ModalPayloadFilter";

const LIMIT = 10;

const dateNow = new Date();
let date30Date = new Date();
date30Date.setDate(date30Date.getDate() - 30);

const DeviceStatistic = (props) => {
  const [sharedState, dispatch] = useContext(GlobalContext);
  const [state, setState] = useState({
    page: 1,
    tpage: 0,
    count: 0,
    startDate: getDateInputFormat(date30Date),
    startTime: "00:00",
    endDate: getDateInputFormat(dateNow),
    endTime: "23:59",
  });

  const [showModal, setShowModal] = useState(false);
  const [payload, setPayload] = useState(new Array(LIMIT).fill(""));
  const params = useParams();
  const handleGetPayload = async (input) => {
    try {
      let offset = 0;
      let page = state.page;
      if (input === "next") {
        setPayload(new Array(LIMIT).fill(""));
        page += 1;
      } else if (input === "prev") {
        setPayload(new Array(LIMIT).fill(""));
        page -= 1;
      } else if (input === "refresh") {
        page = 1;
      }
      offset = (page - 1) * LIMIT;
      let param = `topics=${CONFIG.appName} - ${params.deviceName}&limit=${LIMIT}&offset=${offset}`;
      if (
        state.startDate !== "" &&
        state.startTime !== "" &&
        state.endDate !== "" &&
        state.endTime !== ""
      ) {
        let tStartDate = new Date(`${state.startDate} ${state.startTime}`);
        let tEndDate = new Date(`${state.endDate} ${state.endTime}`);

        if (tStartDate.getTime() >= tEndDate.getTime()) {
          throw new Error("Invalid Range");
        }

        tStartDate = getDateTimeFormat(tStartDate);
        tEndDate = getDateTimeFormat(tEndDate);
        param = `${param}&startDate=${tStartDate}&endDate=${tEndDate}`;
      }

      let { result, count } = await getPayload(param);
      count = count[`${CONFIG.appName} - ${params.deviceName}`];
      const tpage = Math.ceil(count / LIMIT);

      let payload = result[`${CONFIG.appName} - ${params.deviceName}`];
      payload = payload.map((obj) => ({
        ...obj,
        timestamp: getDateFormat2(obj.timestamp),
      }));

      setPayload(payload);
      setState((prevState) => ({ ...prevState, page, tpage, count }));
      if (showModal) setShowModal(false);
    } catch (error) {
      // console.log(error.message);
      dispatch("alert", {
        show: true,
        message: error.message,
        type: "warning",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div>
      <ModalPayloadFilter
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={() => handleGetPayload("refresh")}
        handleChange={handleChange}
        startDate={state.startDate}
        startTime={state.startTime}
        endDate={state.endDate}
        endTime={state.endTime}
      />
      <div className="border border-primary rounded-lg px-3 py-2">
        <div></div>
      </div>
    </div>
  );
};

export default DeviceStatistic;
