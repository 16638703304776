import { CONFIG } from "../configs/constant";
const MONTH = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];
const addZero = (input) => {
  input = input > 9 ? input : `0${input}`;
  return input;
};

export const getDateInputFormat = (input) => {
  input = new Date(input);
  let day = input.getDate();
  let month = input.getMonth();
  const year = input.getFullYear();
  return `${year}-${addZero(month + 1)}-${addZero(day)}`;
};

export const getDateFormat2 = (input) => {
  input = new Date(input);
  let day = input.getDate();
  let month = input.getMonth();
  const year = input.getFullYear();

  let hour = input.getHours();
  let minute = input.getMinutes();

  return `${day} ${MONTH[month]} ${year} ${addZero(hour)}:${addZero(minute)}`;
};

export const getDateFormat3 = (input) => {
  input = new Date(input);
  let day = input.getDate();
  let month = input.getMonth();
  const year = input.getFullYear();

  return `${day} ${MONTH[month]} ${year}`;
};

export const getDateTimeFormat = (input) => {
  const year = input.getFullYear();
  let month = input.getMonth() + 1;
  let date = input.getDate();

  let hours = input.getHours();
  let minutes = input.getMinutes();

  month = month > 9 ? month : `0${month}`;
  date = date > 9 ? date : `0${date}`;
  hours = hours > 9 ? hours : `0${hours}`;
  minutes = minutes > 9 ? minutes : `0${minutes}`;

  return `${year}-${month}-${date} ${hours}:${minutes}`;
};

export const getDeviceStatus = (timeDevice) => {
  if (timeDevice === undefined) return false;
  const timeDiff = (new Date() - new Date(timeDevice)) / 60000;
  if (timeDiff > CONFIG.lifeTimeLimit) return false;
  return true;
};

export const formatter = new Intl.NumberFormat("id-ID", {});
