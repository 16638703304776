import { createContext, useState } from "react";

let iotPlatformApp = JSON.parse(sessionStorage.getItem("iotPlatformApp")) || {};

export const GlobalContext = createContext();
export const GlobalProvider = (props) => {
  const [state, setState] = useState({
    user: {
      token: iotPlatformApp.token || "",
      isLogin: false,
      email: "",
      name: "",
    },
    isLoading: false,
    alert: {
      show: false,
      type: "success",
      message: "Oops there is something wrong",
    },
    confirm: {
      show: false,
      message: "",
      callback: null,
    },
  });
  const dispatch = (topic, data) => {
    switch (topic) {
      case "user":
        setState((prevState) => ({
          ...prevState,
          user: { ...prevState.user, ...data },
        }));
        break;

      case "isLoading":
        setState((prevState) => ({ ...prevState, isLoading: data }));
        break;

      case "alert":
        setState((prevState) => ({
          ...prevState,
          alert: { ...prevState.alert, ...data },
        }));
        break;

      case "confirm":
        setState((prevState) => ({
          ...prevState,
          confirm: { ...prevState.confirm, ...data },
        }));
        break;

      default:
        break;
    }
  };

  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {props.children}
    </GlobalContext.Provider>
  );
};
