import React, { memo } from "react";

const Backdrop = (props) => {
  const handleClick = () => {
    if (props.handleClick) props.handleClick();
  };
  return (
    <div
      className="top-0 left-0 absolute w-full min-h-screen bg-gray-800 bg-opacity-50 z-30 "
      onClick={handleClick}
    />
  );
};

export default memo(Backdrop);
