import {
  axiosDelete,
  axiosGet,
  axiosGetDetail,
  axiosPatch,
  axiosPost,
} from "./axiosService";
const url = "/device";

export const getDevice = (param) => axiosGet(url, param);
export const postDevice = (body) => axiosPost(url, body);
export const getDeviceDetail = (param) => axiosGetDetail(url, param);
export const deleteDevice = (body) => axiosDelete(url, body);
export const patchDevice = (body) => axiosPatch(url, body);
