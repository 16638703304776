import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import { CONFIG } from "../../../../configs/constant";
import MarkerCustom from "../../atoms/MarkerCustom/MarkerCustom";

const LocationMarker = (props) => {
  useMapEvents({
    click(e) {
      props.setPosition([e.latlng.lat, e.latlng.lng]);
    },
  });
  return props.position === null ? null : (
    <Marker position={props.position} icon={MarkerCustom["light"]}></Marker>
  );
};

const EditableMap = (props) => {
  return (
    <MapContainer
      center={
        props.position.length === 0 ? CONFIG.baseLocation : props.position
      }
      zoom={13}
      scrollWheelZoom={false}
      style={{ height: 400, width: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LocationMarker
        position={props.position.length === 0 ? null : props.position}
        setPosition={props.setPosition}
      />
    </MapContainer>
  );
};

export default EditableMap;
