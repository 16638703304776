import { useContext, useState } from "react";
import avatar from "../../../../assets/img/avatar/default-avatar.png";
import { ReactComponent as Android } from "../../../../assets/img/icon/android_black_24dp.svg";
import { ReactComponent as Person } from "../../../../assets/img/icon/person_black_24dp.svg";
import { CONFIG } from "../../../../configs/constant";
import { GlobalContext } from "../../../contexts/globalContext";
import CardDashboard from "../../../ui/atoms/CardDashboard/CardDashboard";
import PageTitle from "../../../ui/atoms/PageTitle/PageTitle";
import ModalProfileChangePassword from "../../../ui/molecules/ModalProfileChangePassword/ModalProfileChangePassword";

let brokerUri = process.env.REACT_APP_BROKER_URI;
brokerUri = brokerUri.replace("2883", "1883");

const Profile = () => {
  const [sharedState, dispatch] = useContext(GlobalContext);
  const { user } = sharedState;
  const [modal, setModal] = useState(null);

  return (
    <div className="mt-2 lg:mt-6">
      <ModalProfileChangePassword
        show={modal === "password"}
        handleClose={() => setModal(null)}
      />

      <PageTitle
        title="Halaman Profil"
        description="Dapatkan informasi akun pengguna dan aplikasi smartmeter."
        icon={<Person fill="#fafafa" className="w-12 lg:w-16 h-12 lg:h-16" />}
      />
      <br />

      <div className="mt-3 grid grid-cols-12 gap-1 lg:gap-4">
        <CardDashboard
          title="Profil Pengguna"
          icon={<Person fill="#fafafa" className="w-6 h-6" />}
          className="mt-8 col-span-12 pt-8 pb-4"
        >
          <div className="absolute top-2 right-4">
            <div
              className="text-sm cursor-pointer"
              onClick={() => setModal("password")}
            >
              Ubah Password
            </div>
          </div>
          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-5 space-y-5 lg:space-y-0 items-center md:pt-6">
            <div className="w-32 flex-shrink-0">
              <div
                className="w-24 h-24 bg-cover bg-center rounded-full mx-auto"
                style={{ backgroundImage: `url(${avatar})` }}
              ></div>
            </div>
            <div className="w-full">
              <div className="grid grid-cols-12 gap-1 lg:gap-4">
                <div className="col-span-12 sm:col-span-3">Username</div>
                <div className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none ">
                  {user.name}
                </div>
              </div>
              <div className="mt-3 grid grid-cols-12 gap-1 lg:gap-4">
                <div className="col-span-12 sm:col-span-3">Email</div>
                <div className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none ">
                  {user.email}
                </div>
              </div>
              <div className="mt-3 grid grid-cols-12 gap-1 lg:gap-4">
                <div className="col-span-12 sm:col-span-3">Password</div>
                <div className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none ">
                  ********
                </div>
              </div>
            </div>
          </div>
        </CardDashboard>
      </div>

      <div className="mt-3 grid grid-cols-12 gap-1 lg:gap-4">
        <CardDashboard
          title="Aplikasi"
          icon={<Android fill="#fafafa" className="w-6 h-6" />}
          className="mt-8 col-span-12 pt-8 pb-4"
        >
          <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-5 space-y-5 lg:space-y-0 items-center">
            <div className="w-full">
              <div className="grid grid-cols-12 gap-1 lg:gap-4">
                <div className="col-span-12 sm:col-span-3">Nama Aplikasi</div>
                <div className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none ">
                  {CONFIG.appName}
                </div>
              </div>
              <div className="mt-3 grid grid-cols-12 gap-1 lg:gap-4">
                <div className="col-span-12 sm:col-span-3">Broker URI</div>
                <div className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none ">
                  mqtt://{brokerUri}
                </div>
              </div>
              <div className="mt-3 grid grid-cols-12 gap-1 lg:gap-4">
                <div className="col-span-12 sm:col-span-3">Kunci Aplikasi</div>
                <div className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none">
                  <div className="flex justify-between items-center">
                    <div>************</div>
                    <div
                      className="bg-secondary cursor-pointer text-white px-1 rounded text-sm"
                      onClick={() => {
                        console.log(process.env.REACT_APP_APP_KEY);
                        navigator.clipboard.writeText(
                          process.env.REACT_APP_APP_KEY
                        );
                      }}
                    >
                      Salin
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardDashboard>
      </div>
    </div>
  );
};

export default Profile;
