const axios = require("axios");
const { Axios } = require("./axiosService");
const url = "/auth/token";

export const postAuth = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await Axios.post(url, body);
      Axios.defaults.headers.common = { Authorization: `bearer ${data.token}` };
      sessionStorage.setItem(
        "iotPlatformApp",
        JSON.stringify({
          username: body.username,
          token: data.token,
        })
      );
      resolve(data);
    } catch (error) {
      error.message = error.response
        ? error.response.data.error
        : error.message;
      reject(error);
    }
  });
};

export const validateToken = (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}`,

        { headers: { Authorization: `Bearer ${token}` } }
      );
      resolve(data);
    } catch (error) {
      error.message = error.response
        ? error.response.data.error
        : error.message;
      reject(error);
    }
  });
};
