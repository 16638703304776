import { ReactComponent as CircleCheck } from "../../../../assets/img/icon/check_circle_black_24dp.svg";
import { ReactComponent as ErrorBlack } from "../../../../assets/img/icon/error_black_24dp.svg";
import { COLOR } from "../../../../configs/constant";
// import ButtonLg from "../ButtonLg/ButtonLg";

const ALERT = {
  success: {
    title: "sukses",
    icon: (
      <CircleCheck
        fill={COLOR.SUCCESS}
        className="w-20 sm:w-24 h-20 sm:h-24 mx-auto"
      />
    ),
    color: COLOR.SUCCESS,
  },
  warning: {
    title: "perhatian",
    icon: (
      <ErrorBlack
        fill={COLOR.WARNING}
        className="w-20 sm:w-24 h-20 sm:h-24 mx-auto"
      />
    ),
    color: COLOR.WARNING,
  },
};

const Alert = (props) => {
  const handleClose = () => {
    if (props.handleClose) props.handleClose();
  };
  const type = props.data.type || "warning";

  return (
    <div
      className={
        "fixed top-0 left-0 right-0 min-h-screen bg-gray-900 bg-opacity-40 px-2 " +
        (props.data.show ? "opacity-100" : "opacity-0")
      }
      style={{ zIndex: props.data.show ? "30" : "-10" }}
    >
      <div
        className={
          "max-w-sm md:max-w-lg bg-white shadow-xl mx-auto px-4 sm:px-10 py-5 sm:py-6 rounded-xl relative duration-75 ease-in mt-16 transform " +
          (props.data.show ? "opacity-100 scale-105" : "opacity-30")
        }
      >
        <div>{ALERT[type].icon}</div>

        <div className="mt-3 text-center max-w-2xl mx-auto">
          <h3 className="font-semibold text-xl capitalize">
            {ALERT[type].title}
          </h3>
          <p className="mt-1">
            {props.data.message || "Nampaknya ada yang salah."}
          </p>
        </div>
        <div className="mt-5 flex space-x-2 justify-center">
          <button
            className="w-20 px-2 py-0.5 text-base text-white font-light bg-gradient-to-tr from-gray-600 to-red-800 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-red-300"
            onClick={handleClose}
          >
            Tutup
          </button>
        </div>
      </div>
    </div>
  );
};

export default Alert;
