import Modal from "../../atoms/Modal/Modal";

const ModalStatisticFilter = (props) => {
  return (
    <Modal
      show={props.show}
      handleClose={props.handleClose}
      handleSubmit={props.handleSubmit}
      submitLabel="Buat"
    >
      <div className="mt-3 md:mt-5 grid grid-cols-12 items-center">
        <div className="col-span-12 sm:col-span-3">Nama Perangkat</div>
        <select
          name="deviceName"
          className="col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
          value={props.deviceName}
          onChange={props.handleChange}
        >
          {props.devices.map((device) => (
            <option
              key={"option" + device.deviceName}
              value={device.deviceName}
            >
              {device.deviceName}
            </option>
          ))}
        </select>
      </div>

      <div className="mt-3 md:mt-5 grid grid-cols-12 items-center">
        <div className="col-span-12 sm:col-span-3">Waktu Awal </div>
        <div className="col-span-12 sm:col-span-9 flex flex-col sm:flex-row space-x-0 sm:space-x-3 space-y-1 sm:space-y-0">
          <input
            type="date"
            className={
              "w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
            }
            name="startDate"
            value={props.startDate}
            onChange={props.handleChange}
          />
          <input
            type="time"
            className={
              "w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
            }
            name="startTime"
            name="startTime"
            value={props.startTime}
            onChange={props.handleChange}
          />
        </div>
      </div>

      <div className="mt-3 md:mt-5 grid grid-cols-12 items-center">
        <div className="col-span-12 sm:col-span-3">Waktu Akhir </div>
        <div className="col-span-12 sm:col-span-9 flex flex-col sm:flex-row space-x-0 sm:space-x-3 space-y-1 sm:space-y-0">
          <input
            type="date"
            className={
              "w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
            }
            name="endDate"
            value={props.endDate}
            onChange={props.handleChange}
          />
          <input
            type="time"
            className={
              "w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
            }
            name="endTime"
            value={props.endTime}
            onChange={props.handleChange}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalStatisticFilter;
