import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const ChartLine = (props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={props.data}
        margin={{
          top: 20,
          right: 10,
          left: -30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type={props.type} dataKey={props.dataKeyX || "_id"}>
          {props.xAxisLabel && (
            <Label
              value={props.xAxisLabel}
              offset={-3}
              position="insideBottom"
            />
          )}
        </XAxis>
        <YAxis
          label={{
            value: props.yAxisLabel,
            angle: -90,
            position: "insideLeft",
            offset: 15,
          }}
        />
        <Tooltip />
        <Line
          type="number"
          dataKey={props.dataKeyY}
          stroke={props.stroke || "#82ca9d"}
          strokeWidth={2}
          dot={false}
          isAnimationActive={props.animation || false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartLine;
