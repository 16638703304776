import {axiosGet, axiosPost} from "./axiosService";


class HistoryService {
    static path = "history"
    static async getHistory(name) {
        return await axiosGet(`${this.path}?name=${name}`);
    }

    static async addHistory(body) {
        return await axiosPost(`${this.path}`, body);
    }
}

export default HistoryService;