import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CONFIG, GRAPH_COLOR } from "../../../../../configs/constant";
import { getDateFormat2, getDeviceStatus } from "../../../../../helpers/helper";
import { getPayload } from "../../../../../services/httpServices/payloadService";
import { client } from "../../../../../services/mqttServices/mqttService";
import ChartLine from "../../../molecules/LineChart/LineChart";
import SensorService from "../../../../../services/SensorService/SensorService";

const LIMIT = 50;
const DeviceMonitor = (props) => {
  const [state, setState] = useState({
    status: false,
  });
  const [payload, setPayload] = useState();
  const params = useParams();

  const handleGetPayload = async () => {
    try {
      const { result } = await getPayload(
        `topics=${CONFIG.appName} - ${params.deviceName}&limit=${LIMIT}&orderBy=-timestamp`
      );
      let payload = result[`${CONFIG.appName} - ${params.deviceName}`];
      let status = false;
      if (payload.length > 0) {
        let latestPayload = payload[0];
        status = getDeviceStatus(latestPayload["timestamp"]);
        setState((prevState) => ({
          ...prevState,
          status,
          lastMessage: getDateFormat2(latestPayload["timestamp"]),
        }));
      }
      payload = payload.map((obj) => ({
        ...obj,
        timestamp: getDateFormat2(obj.timestamp),
      }));
      payload.reverse();

      setPayload(payload);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleMqttClient = () => {
    client.subscribe(params.deviceName);
    client.on("message", async (topic, message) => {
      message = message.toString();
      if (topic !== params.deviceName) return;
      let jsonMessage = JSON.parse(message);
      jsonMessage["timestamp"] = getDateFormat2(new Date());
      await SensorService.cekSensor(jsonMessage["payload"], params.deviceName);
      console.log(jsonMessage);

      setPayload((prevState) => {
        let res = [...prevState, jsonMessage];
        if (res.length > LIMIT) res.shift();
        return res;
      });
      setState({
        status: true,
        lastMessage: getDateFormat2(new Date()),
      });
    });
  };

  useEffect(() => {
    handleGetPayload();
    handleMqttClient();
  }, []);

  // useEffect(() => {
  //   console.log(payload);
  // }, [payload]);

  return (
    <div>
      <div className="text-sm sm:text-base">
        <div className="bg-primary bg-opacity-10 rounded px-2 py-1 flex flex-col justify-between ">
          <div className="flex justify-between">
            <div>Data Terakhir : {state.lastMessage || "-"}</div>
            <div>
              Status :{" "}
              {state.status === true ? (
                  <div className="inline-block bg-secondary text-white px-2 rounded-lg">
                    Aktif
                  </div>
              ) : (
                  <div className="inline-block bg-warning text-white px-2 rounded-lg">
                    Tidak Aktif
                  </div>
              )}
            </div>
          </div>
          {/*<div className="cursor-pointer">*/}
          {/*  Lihat History Notifikasi*/}
          {/*</div>*/}
        </div>
        <div className="mt-3 grid grid-cols-12">
          {CONFIG.variable.map((obj, idx) => (
            <div className="col-span-12 sm:col-span-6" key={"chart-" + obj.key}>
              <div className="font-semibold">
                &bull; {`${obj.label} ${obj.unit ? "(" + obj.unit + ")" : ""}`}
              </div>
              <div className="h-44 text-xs p-2">
                <ChartLine
                  data={payload}
                  dataKeyY={`payload.${obj.key}`}
                  stroke={GRAPH_COLOR[idx]}
                  //   yAxisLabel={obj.label}
                  dataKeyX="timestamp"
                  xAxisLabel="time"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeviceMonitor;
