import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { CONFIG } from "../../../../configs/constant";
import MarkerCustom from "../../atoms/MarkerCustom/MarkerCustom";
import notfound from "../../../../assets/img/picture/not-found.png";
import { useState } from "react";

const MapLeaflet = (props) => {
  const [showData, setShowData] = useState(true);
  return (
    <MapContainer
      center={props.center.length === 0 ? CONFIG.baseLocation : props.center}
      zoom={13}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {props.devices.map((device, idx) => {
        if (!device) return null;
        let position = [];
        if (device.position) {
          position[0] = device.position.lat;
          position[1] = device.position.lng;
        }
        if (position.length === 0) return null;
        return (
          <Marker
            key={"marker-" + idx}
            icon={device.status ? MarkerCustom["light"] : MarkerCustom["dark"]}
            position={position}
          >
            <Popup>
              <div
                className="-mx-2 -my-1 cursor-pointer"
                onClick={() => setShowData(!showData)}
              >
                <div className="relative w-full">
                  <img
                    src={device.description ? device.description.img : notfound}
                    alt=""
                    className="object-cover h-24 w-full"
                  />
                  <div className="absolute inset-0 bg-gray-900 bg-opacity-10"></div>
                  <h6 className="font-semibold border-t border-b py-1 bg-gray-100 px-1">{`ID Perangkat : ${device.deviceName}`}</h6>
                </div>

                {showData ? (
                  device.message &&
                  device.message.payload && (
                    <>
                      <div className="mt-1 flex flex-wrap w-52">
                        {CONFIG.variable.map((obj, idx) => (
                          <div
                            key={"sensor-" + obj.key + idx}
                            className={"w-1/2  "}
                          >
                            <div className="py-0.5">
                              &bull;
                              <span className="inline-block w-10 ml-1">
                                {obj.key}
                              </span>
                              {`: ${device.message.payload[obj.key] || "-"}`}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="mt-2 text-gray-600 text-xs">
                        Data terakhir: {device.message.ftimestamp}
                      </div>
                    </>
                  )
                ) : (
                  <div className="mt-1 flex flex-wrap w-52">
                    <div className="py-0.5">
                      &bull;
                      <span className="inline-block w-16 ml-1">Nama</span>
                      {`: ${
                        device.description ? device.description.label : "-"
                      }`}
                    </div>
                    <div className="py-0.5">
                      &bull;
                      <span className="inline-block w-16 ml-1">Tanggal</span>
                      {`: ${device.createdAt}`}
                    </div>
                    <div className="py-0.5">
                      &bull;
                      <span className="inline-block w-16 ml-1">Deskripsi</span>
                      {`: ${
                        device.description ? device.description.detail : "-"
                      }`}
                    </div>
                  </div>
                )}
              </div>
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};

export default MapLeaflet;
