import Modal from "../../atoms/Modal/Modal";
import {useEffect, useState} from "react";
import {getDateFormat2} from "../../../../helpers/helper";
import HistoryService from "../../../../services/httpServices/historyService";


const ModalHistoryNotify = (props) => {
    const [data, setData] = useState([]);

    const HandleGetHistory = (name) => {
        try{
            HistoryService.getHistory(name).then((response) => {
                setData(response.result);
            }).catch(error => {

            })
            // let getValue = localStorage.getItem(`notify_${name}`);
            // if(getValue) {
            //     getValue = JSON.parse(getValue);
            //     setData(getValue.reverse());
            // }
        }catch (error){

        }
    }

    useEffect(() => {
        if(props.deviceName && props.deviceName !== "") HandleGetHistory(props.deviceName);

    }, [props.show]);

    return(
        <Modal
            show={props.show}
            handleClose={props.handleClose}
            handleSubmit={() => props.handleClose()}
            submitLabel="Ok"
        >
            <div className="flex flex-col space-y-2 w-full h-[300px] overflow-auto bg-primary bg-opacity-10 rounded-md px-2 py-1">
                {data.map((items, idx) => (
                    <div key={`${idx}-D`} className="px-2 py-1 bg-warning bg-opacity-50 rounded-md">
                        <p className="text-gray-800">Name Device : {items.name}</p>
                        <p className="text-gray-800">Message warning : {items.message}</p>
                        <p className="text-gray-800">Time : {getDateFormat2(`${items.createdAt}`)}</p>
                    </div>
                ))}

            </div>
        </Modal>
    )
}

export default ModalHistoryNotify;