import { useContext, useEffect, useState } from "react";
import { Redirect, Route } from "react-router";
import { getUserProfile } from "../../../services/httpServices/userService";
import { GlobalContext } from "../../contexts/globalContext";
import Loader from "../../ui/atoms/Loader/Loader";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [sharedState, dispatch] = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);

  const handleGetUserProfile = async () => {
    try {
      if (sharedState.user.isLogin === true || sharedState.user.token === "") {
        setIsLoading(false);
        return;
      }
      const { result } = await getUserProfile();
      dispatch("user", {
        name: result.displayName,
        email: result.email,
        isLogin: true,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch("alert", {
        type: "warning",
        message: "Your session already expired",
        show: true,
      });
      sessionStorage.clear();
      dispatch("user", {
        token: "",
        isLogin: false,
        email: "",
        name: "",
      });
    }
  };

  useEffect(() => {
    handleGetUserProfile();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (sharedState.user.isLogin) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
