import L from "leaflet";
import darkPin from "../../../../assets/img/icon/dark-pin.png";
import lightPin from "../../../../assets/img/icon/light-pin.png";

const lightPinMarker = new L.Icon({
  iconUrl: lightPin,
  iconRetinaUrl: lightPin,
  iconAnchor: null,
  popupAnchor: [0, -20],
  // popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(23, 35),
  className: "",
});

const darkPinMarker = new L.Icon({
  iconUrl: darkPin,
  iconRetinaUrl: darkPin,
  iconAnchor: null,
  popupAnchor: [0, -20],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(23, 35),
  className: "",
});

const CustomMarker = {
  dark: darkPinMarker,
  light: lightPinMarker,
};

export default CustomMarker;
