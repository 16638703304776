import brandlogo from "../../../../assets/img/logo/xirka-icon.png";
import PageTitle from "../../../ui/atoms/PageTitle/PageTitle";

const About = (props) => {
  return (
    <div className="mt-2 lg:mt-6">
      <PageTitle
        title="Tentang Xirka"
        description="Mengenal PT Xirka Silicon Tehcnology"
        icon={<img src={brandlogo} className="w-12 h-12 " />}
      />
      <br />
      <div className="text-gray-900 text-opacity-80">
        <div className="bg-white rounded-xl px-4 py-5 shadow-lg">
          <p className="max-w-4xl">
            PT. Xirka Silicon Technology (XST) adalah perusahaan semikonduktor
            pertama di Indonesia yang menciptakan Sirkuit terintegrasi yang
            inovatif (IC) dan untuk menyediakan produk elektronik massal untuk
            gaya hidup yang dinamis. Misi kami adalah menggabungkan keahlian
            teknologi tinggi, kualitas tinggi sumber daya manusia, dan
            kreativitas yang tinggi untuk nilai tambah yang unggul produk.
          </p>

          <div className="text-sm flex flex-col lg:flex-row space-x-0 lg:space-x-10">
            <div>
              <h3 className="font-semibold mt-3">Kantor Pusat</h3>
              <p>
                Gedung CM, Jl. Matraman I No 9 Jakarta Timur, 13150 Indonesia
              </p>
              <p>Phone. +62-21-819-8700 & Fax. +62-21-2850-7071</p>
              <p>Email : info@xirkachipset.com</p>
            </div>

            <div>
              <h3 className="font-semibold mt-3">Departemen R&D</h3>
              <p>
                Kompleks Puri Syailendra Kav.30 Jl. Lemah Neundeut, Setrasari
                Bandung, 40164
              </p>
              <p>Phone. +62-22-2014189 & Fax. +62-22-2014253</p>
              <p>Email : cs@xirkachipset.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
