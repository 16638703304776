import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import avatar from "../../../../assets/img/avatar/default-avatar.png";
import { ReactComponent as CloseBlack } from "../../../../assets/img/icon/close_black_24dp.svg";
import logo from "../../../../assets/img/logo/xirka-icon.png";
import { COLOR } from "../../../../configs/constant";
import { client } from "../../../../services/mqttServices/mqttService";
import { GlobalContext } from "../../../contexts/globalContext";

const NavbarDashboard = (props) => {
  const [sharedState, dispatch] = useContext(GlobalContext);
  const [popUp, setPopUp] = useState(false);
  const history = useHistory();

  const handleConfirmLogout = () => {
    if (!popUp) return;
    setPopUp(false);
    dispatch("confirm", {
      show: true,
      message: "Lanjut keluar aplikasi?",
      callback: () => {
        dispatch("confirm", { show: false });
        dispatch("isLoading", true);
        client.end();
        setTimeout(() => {
          sessionStorage.clear();
          dispatch("user", {
            token: "",
            isLogin: false,
            email: "",
            name: "",
          });
          dispatch("isLoading", false);
          history.push("/login");
        }, 500);
      },
    });
  };

  return (
    <nav className="w-full py-2 bg-white fixed shadow-lg top-0 z-20">
      <div className="px-3 xl:container mx-auto flex justify-between items-center">
        {/* LEFT NAV  */}
        <div className="flex items-center">
          <div className="px-1.5 rounded flex items-center space-x-2">
            <img src={logo} alt="logo" className="w-8" />
            <div>
              <span className="text-red-500">XIRKA</span> Iot Platform
            </div>
          </div>
        </div>
        {/* RIGHT NAV  */}
        <div className="flex items-center space-x-5">
          <div className="relative">
            <div
              className="flex space-x-2 items-center cursor-pointer"
              onClick={() => setPopUp(!popUp)}
            >
              <div
                className="rounded-full bg-center bg-cover"
                style={{
                  width: "2rem",
                  height: "2rem",
                  backgroundImage: `url("${avatar}")`,
                }}
              />
              <p className="hidden sm:block">{sharedState.user.name}</p>
            </div>
            <div
              className={
                "absolute right-0 duration-150 ease-in-out  " +
                (popUp
                  ? "opacity-100 top-12 h-full "
                  : "opacity-0 top-0 h-0 overflow-y-hidden")
              }
              style={{ zIndex: popUp ? "10" : "-100" }}
            >
              <div className="w-64 bg-gradient-to-tr from-gray-600 to-red-800 shadow-lg text-white rounded-xl px-3 py-3 relative">
                <div
                  className="absolute top-1.5 right-1.5 cursor-pointer"
                  onClick={() => setPopUp(false)}
                >
                  <div className="w-3 h-3 bg-white rounded-full shadow-lg">
                    <CloseBlack
                      fill={COLOR.PRIMARY}
                      className="w-3 h-3 mx-auto"
                    />
                  </div>
                </div>
                <div
                  className="w-24 h-24 border-2 border-white bg-white rounded-full mx-auto bg-center bg-cover "
                  style={{
                    backgroundImage: `url("${avatar}")`,
                  }}
                ></div>
                <div className="text-center">
                  <div className="mt-2 text-lg">{sharedState.user.name}</div>
                  <div className="-mt-1 text-sm text-white text-opacity-60">
                    {sharedState.user.email}
                  </div>
                  <div className="mt-3">
                    <div
                      className={
                        "text-sm bg-white text-gray-900 p-1 rounded " +
                        (popUp ? "cursor-pointer" : "")
                      }
                      onClick={handleConfirmLogout}
                    >
                      Keluar
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default NavbarDashboard;
