const axios = require("axios");
let iotPlatformApp = JSON.parse(sessionStorage.getItem("iotPlatformApp")) || {};

export const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URI}/api/v1`,
});

if (iotPlatformApp.token) {
  Axios.defaults.headers.common = {
    Authorization: `Bearer ${iotPlatformApp.token}`,
  };
}

export const axiosGet = (url, param) => {
  param = param ? `?${param}` : "";
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await Axios.get(`${url}${param}`);
      resolve(data);
    } catch (error) {
      error.message = error.response
        ? error.response.data.error
        : error.message;
      reject(error);
    }
  });
};

export const axiosGetDetail = (url, param) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await Axios.get(`${url}/${param}`);
      resolve(data);
    } catch (error) {
      error.message = error.response
        ? error.response.data.error
        : error.message;
      reject(error);
    }
  });
};

export const axiosPost = (url, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await Axios.post(url, body);
      resolve(data);
    } catch (error) {
      error.message = error.response
        ? error.response.data.error
        : error.message;
      reject(error);
    }
  });
};

export const axiosPatch = (url, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await Axios.patch(url, body);
      resolve(data);
    } catch (error) {
      error.message = error.response
        ? error.response.data.error
        : error.message;
      reject(error);
    }
  });
};

export const axiosDelete = (url, body) => {
  // param = param ? `?${param}` : "";
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await Axios.delete(url, { data: body });
      resolve(data);
    } catch (error) {
      error.message = error.response
        ? error.response.data.error
        : error.message;
      reject(error);
    }
  });
};
