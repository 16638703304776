import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import { GlobalProvider } from "./components/contexts/globalContext";
import ProtectedRoute from "./components/hoc/ProtectedRoute/ProtectedRoute";
import Login from "./components/pages/Login/Login";
import DashboardRoute from "./components/routers/DashboardRoutes/DashboardRoutes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <GlobalProvider>
      <ToastContainer
          position="top-right"
          autoClose={10000}
          limit={5}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
      />
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <ProtectedRoute path="/dashboard" component={DashboardRoute} />
          <Route
            exact
            path="/"
            component={() => <Redirect to="/dashboard" />}
          />
        </Switch>
      </Router>
    </GlobalProvider>
  );
}

export default App;
