const ButtonLg = (props) => {
  const onClick = () => {
    props.onClick();
  };
  return (
    <button
      className={
        "w-full px-4 py-2 bg-gradient-to-tr from-dark-royale to-gray-900 rounded-xl text-white shadow-lg font-light text-sm md:text-base focus:outline-none focus:ring-2 ring-red-200 " +
        props.className
      }
      onClick={onClick}
    >
      {props.children}
      <div className="">{props.label}</div>
    </button>
  );
};
export default ButtonLg;
