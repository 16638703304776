const mqtt = require("mqtt");
const appKey = process.env.REACT_APP_APP_KEY || "";

export const client = mqtt.connect(
  `mqtt://${process.env.REACT_APP_BROKER_URI}`,
  {
    username: appKey.split(".")[0],
    password: appKey.split(".")[1],
    reconnectPeriod: 5000,
  }
);

client.on("connect", () => {
  console.log("Broker Connection established");
});

client.on("error", (err) => {
  console.log(err.message);
});
