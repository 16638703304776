import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Dashboard } from "../../../../assets/img/icon/dashboard_black_24dp.svg";
import { ReactComponent as Board } from "../../../../assets/img/icon/developer_board_black_24dp.svg";
import { ReactComponent as FolderIcon } from "../../../../assets/img/icon/folder_black_24dp.svg";
import { ReactComponent as MapIcon } from "../../../../assets/img/icon/map_black_24dp.svg";
import { ReactComponent as WifiTethering } from "../../../../assets/img/icon/wifi_tethering_black_24dp.svg";
import { COLOR, CONFIG } from "../../../../configs/constant";
import {
  formatter,
  getDateFormat2,
  getDateFormat3,
  getDescription,
  getDeviceStatus,
} from "../../../../helpers/helper";
import { getDevice } from "../../../../services/httpServices/deviceService";
import { getPayload } from "../../../../services/httpServices/payloadService";
import { client } from "../../../../services/mqttServices/mqttService";
import CardDashboard from "../../../ui/atoms/CardDashboard/CardDashboard";
import PageTitle from "../../../ui/atoms/PageTitle/PageTitle";
import MapLeaflet from "../../../ui/molecules/MapLeaflet/MapLeaflet";

// new Intl.NumberFormat(locale).format(count);
const cards = [
  {
    name: "Device",
    label: "Perangkat",
    icon: <Board fill={`${COLOR.PRIMARY}90`} className="w-12 h-12" />,
    path: "/dashboard/device",
  },
  {
    name: "Sensor Data",
    label: "Data Sensor",
    icon: <FolderIcon fill={`${COLOR.PRIMARY}90`} className="w-12 h-12" />,
    path: "/dashboard/payload",
  },
  {
    name: "Connection Status",
    label: "Status Koneksi",
    icon: <WifiTethering fill={`${COLOR.PRIMARY}90`} className="w-12 h-12" />,
    path: "/dashboard",
  },
  // {
  //   label: "perangkat",
  //   icon: <Dashboard fill={`${COLOR.PRIMARY}90`} className="w-12 h-12" />,
  //   path: "/device",
  // },
  // {
  //   label: "mitra",
  //   icon: <Groups fill={COLOR.PRIMARY} className="w-12 h-12" />,
  //   path: "/partner",
  // },
];

let job;

const DashboardMain = () => {
  const history = useHistory();
  const [devices, setDevices] = useState(new Array(5).fill(""));
  const [center, setCenter] = useState([]);
  const [value, setValue] = useState({
    Device: "",
    "Sensor Data": "",
    "Connection Status": "Not Connected",
  });

  const handleGetData = async () => {
    try {
      let response = await getDevice(`application=${CONFIG.appName}`);
      let center = [0, 0];
      let numDefinedPosition = 0;

      response.result = response.result.filter(
        (obj) => !CONFIG.root.includes(obj.deviceName)
      );

      response.result = response.result.map((obj) => {
        if (obj.position !== undefined) {
          center[0] += obj.position.lat;
          center[1] += obj.position.lng;
          numDefinedPosition++;
        }
        if (obj.description && obj.description.img) {
          obj.description.img = `${CONFIG.baseImgUrl}/${obj.description.img}`;
        }

        obj.createdAt = getDateFormat3(obj.createdAt);

        return {
          ...obj,
        };
      });

      if (numDefinedPosition !== 0) {
        center[0] = center[0] / numDefinedPosition;
        center[1] = center[1] / numDefinedPosition;
      }

      await setCenter(center);
      await setDevices(response.result);
      handleGetPayload(response.result);
      handleMqttClient(response.result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleMqttClient = async (devices) => {
    let arrDevice = devices.map((device) => `${device.deviceName}`);
    client.subscribe(arrDevice);
    client.on("message", (topic, message) => {
      message = message.toString();
      console.log(message);
      const idx = devices.findIndex((device) => device.deviceName === topic);
      if (idx < 0) return;
      let jsonMessage = JSON.parse(message);
      jsonMessage["timestamp"] = new Date();
      jsonMessage["ftimestamp"] = getDateFormat2(new Date());
      setDevices((prevState) => {
        let res = [...prevState];
        res[idx]["message"] = jsonMessage;
        res[idx]["status"] = getDeviceStatus(new Date());
        return res;
      });
    });
  };

  const handleGetPayload = async (inputDevices) => {
    try {
      const topics = inputDevices.reduce((accumulator, device, idx) => {
        if (idx === 0)
          return `${accumulator}${CONFIG.appName} - ${device.deviceName}`;
        return `${accumulator},${CONFIG.appName} - ${device.deviceName}`;
      }, "");

      let { count, result } = await getPayload(`limit=1&topics=${topics}`);
      let tempCount = 0;

      Object.keys(count).forEach((key) => {
        tempCount += count[key];
      });

      inputDevices = inputDevices.map((device) => {
        if (result[`${CONFIG.appName} - ${device.deviceName}`].length !== 0) {
          device["message"] =
            result[`${CONFIG.appName} - ${device.deviceName}`][0];
          device["message"]["ftimestamp"] = getDateFormat2(
            device["message"]["timestamp"]
          );
          device["status"] = getDeviceStatus(device["message"]["timestamp"]);
        }
        return device;
      });

      setDevices(inputDevices);
      setValue((prevState) => ({
        ...prevState,
        Device: formatter.format(inputDevices.length),
        "Sensor Data": formatter.format(tempCount),
      }));
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  useEffect(() => {
    setValue((prevState) => ({
      ...prevState,
      "Connection Status":
        client.connected === true ? "Connected" : "Not connected",
    }));
  }, [client.connected]);

  useEffect(() => {
    job = setInterval(() => {
      setDevices((prevState) => {
        prevState = prevState.map((device) => {
          device["status"] = getDeviceStatus(device["timestamp"]);
          return device;
        });
        return prevState;
      });
    }, 1000 * 60 * CONFIG.lifeTimeLimit);

    return () => {
      if (job !== undefined) clearInterval(job);
    };
  }, [devices.message]);

  return (
    <div className="mt-2 lg:mt-6">
      <PageTitle
        title="Halaman Dasbor"
        description="Dapatkan informasi tentang perangkat smartmeter meliputi distribusi serta data sensornya."
        icon={
          <Dashboard fill="#fafafa" className="w-12 lg:w-16 h-12 lg:h-16" />
        }
      />
      <br />

      <div className="mt-6">
        <div className="grid grid-cols-12 gap-3">
          {cards.map((card) => (
            <div
              key={"card-" + card.label}
              className="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3 border border-primary px-3 py-2 rounded-lg shadow-lg hover:bg-primary hover:bg-opacity-20 cursor-pointer"
            >
              {devices.length !== 0 && devices[0]._id === undefined ? (
                <div className="flex space-x-3 items-center">
                  <div className="flex-shrink-0 w-12 h-12 bg-primary rounded-full animate-pulse" />
                  <div className="w-full ">
                    <div className="w-1/2 h-5 bg-primary rounded-lg animate-pulse" />
                    <div className="w-full mt-1 h-5 bg-primary rounded-lg animate-pulse" />
                  </div>
                </div>
              ) : (
                <div className="flex space-x-3 items-center">
                  <div className="w-12 flex-shrink-0 ">{card.icon}</div>
                  <div>
                    <div className="text-sm capitalize text-gray-600 text-opacity-50">
                      {card.label}
                    </div>

                    <div className="text-lg">{value[card.name] || "-"}</div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="mt-3 grid grid-cols-12 gap-4">
        <CardDashboard
          title="Lokasi Perangkat"
          icon={<MapIcon fill="#fafafa" className="w-6 h-6" />}
          className="mt-8 col-span-12 pt-5 pb-4"
        >
          <div className="flex flex-col lg:flex-row lg:space-x-3 space-y-3 lg:space-y-0">
            <div className="w-full rounded-lg h-96 shadow-lg overflow-hidden">
              {center.length !== 0 && (
                <MapLeaflet devices={devices} center={center} />
              )}
            </div>
            <div className="w-full lg:w-60 flex-shrink-0 text-sm">
              <div className="font-semibold">Catatan</div>
              <table className="w-full mt-1">
                <tbody className="">
                  {CONFIG.variable.map((obj, idx) => (
                    <tr
                      className={
                        "col-span-3 border-b " +
                        (idx % 2 === 0 ? "bg-gray-100" : "")
                      }
                      key={"note-" + idx}
                    >
                      <td className="flex-shrink-0 py-0.5 px-1">{`${obj.key}`}</td>
                      <td className="flex-shrink-0 py-0.5 px-1"> :</td>
                      <td className="flex-shrink-0 py-0.5 px-1">{`${obj.label}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </CardDashboard>
      </div>
      <div className="mt-3 grid grid-cols-12 gap-4">
        <CardDashboard
          title="Perangkat"
          icon={<Board fill="#fafafa" className="w-6 h-6" />}
          className="mt-8 col-span-12"
        >
          <div className="w-full overflow-x-auto mb-3">
            <table className="w-full mt-4">
              <thead>
                <tr className="border-t bg-red-900 text-white">
                  <th className="text-left px-1 py-2 w-48">Perangkat</th>
                  <th className="text-left px-1 py-2 w-48">Penempatan</th>
                  <th className="text-left px-1 py-2 w-32">Koordinat Lokasi</th>
                  <th className="text-left px-1 py-2 w-32">Status</th>
                  <th className="text-left px-1 py-2 w-48">
                    Pembaruan Terakhir
                  </th>
                  {/* <th className="text-left px-1 py-2 w-48">Data</th> */}
                </tr>
              </thead>
              <tbody>
                {devices.map((device, idx) => {
                  if (device._id === undefined)
                    return (
                      <tr className="" key={"dummy-device-" + idx}>
                        <td className="px-1 py-2">
                          <div className="bg-primary animate-pulse h-6 rounded" />
                        </td>
                        <td className="px-1 py-2">
                          <div className="bg-primary animate-pulse h-6 rounded" />
                        </td>
                        <td className="px-1 py-2">
                          <div className="bg-primary animate-pulse h-6 rounded" />
                        </td>
                        <td className="px-1 py-2">
                          <div className="bg-primary animate-pulse h-6 rounded" />
                        </td>
                        <td className="px-1 py-2">
                          <div className="bg-primary animate-pulse h-6 rounded" />
                        </td>
                      </tr>
                    );
                  return (
                    <tr
                      key={"device-" + device._id}
                      className={
                        "cursor-pointer border-b  hover:bg-primary hover:bg-opacity-20 " +
                        (idx === 0 ? "border-t" : "") +
                        (idx % 2 === 0 ? " bg-primary bg-opacity-10" : "")
                      }
                      onClick={() =>
                        history.push(`/dashboard/device/${device.deviceName}`)
                      }
                    >
                      <td className="px-1 py-2">{device.deviceName}</td>
                      <td className="px-1 py-2">
                        {device.description ? device.description.label : "-"}
                      </td>
                      <td className="px-1 py-2">
                        {device.position ? (
                          <span className="bg-secondary text-white rounded-md inline-block px-1.5">
                            Telah Diatur
                          </span>
                        ) : (
                          <span className="bg-warning text-white rounded-md inline-block px-1.5">
                            Belum Diatur
                          </span>
                        )}
                      </td>
                      <td className="px-1 py-2">
                        {device.status ? (
                          <span className="bg-secondary text-white rounded-md inline-block px-1.5">
                            Aktif
                          </span>
                        ) : (
                          <span className="bg-warning text-white rounded-md inline-block px-1.5">
                            Tidak Aktif
                          </span>
                        )}
                      </td>
                      <td className="px-1 py-2">
                        {device.message ? device.message.ftimestamp : "-"}
                      </td>
                      {/* <td className="px-1 py-2 w-32">
                        {device.message
                          ? JSON.stringify(device.message.payload)
                          : "-"}
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </CardDashboard>
      </div>
    </div>
  );
};

export default DashboardMain;
