import { useContext, useEffect, useState } from "react";
import { CONFIG } from "../../../../configs/constant";
import { patchDevice } from "../../../../services/httpServices/deviceService";
import { GlobalContext } from "../../../contexts/globalContext";
import Modal from "../../atoms/Modal/Modal";
import MapLeafletEditable from "../MapLeafletEditable/MapLeafletEditable";

const ModalDeviceEdit = (props) => {
  const [state, setState] = useState({
    // description: props.description || "",
    position: props.position || [],
  });
  const [sharedState, dispatch] = useContext(GlobalContext);
  const handleSubmit = async () => {
    try {
      dispatch("isLoading", true);
      if (state.position.length === 0)
        throw new Error("Position can't be blank");

      await patchDevice({
        deviceName: props.deviceName,
        application: CONFIG.appName,
        position: {
          lat: state.position[0],
          lng: state.position[1],
        },
      });
      props.setPosition({
        lat: state.position[0],
        lng: state.position[1],
      });
      props.handleClose();
      dispatch("isLoading", false);
    } catch (error) {
      dispatch("isLoading", false);
      dispatch("alert", {
        show: true,
        message: error.message,
        type: "warning",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setState((prevState) => ({ ...prevState, position: props.position || [] }));
  }, [props.position]);

  return (
    <Modal
      show={props.show}
      handleClose={() => {
        setState({
          // description: props.description || "",
          position: props.position || [],
        });
        props.handleClose();
      }}
      handleSubmit={handleSubmit}
    >
      <div className="mt-3 md:mt-5 grid grid-cols-12 items-center">
        <div className="col-span-12 sm:col-span-3">Nama Perangkat </div>
        <input
          type="text"
          className={
            "col-span-12 sm:col-span-9 w-full bg-primary bg-opacity-20 focus:bg-opacity-30 text-gray-700 text-opacity-70 px-3 py-1 rounded-lg focus:outline-none "
          }
          name="deviceName"
          value={props.deviceName}
          onChange={handleChange}
          disabled={true}
        />
      </div>

      <div className="mt-3 md:mt-5">
        <div className="">Lokasi </div>
        <div className="relative mt-3">
          {state.position.length !== 0 && (
            <div className="absolute z-10 top-1 sm:right-1">
              <div className="bg-primary text-sm text-white rounded px-1 bg-opacity-90">
                Lat : {state.position[0]}, Lng : {state.position[1]}
              </div>
            </div>
          )}
          <MapLeafletEditable
            position={state.position}
            setPosition={(e) => {
              setState((prevState) => ({ ...prevState, position: e }));
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeviceEdit;
