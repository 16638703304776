const PageTitle = (props) => {
  return (
    <div className="no-print bg-primary rounded-lg px-3 py-2 shadow-xl">
      <div className="flex lg:flex-row flex-col space-x-0 lg:space-x-4 items-center">
        <div>{props.icon}</div>
        <div className="text-white text-center lg:text-left">
          <h1 className="text-xl lg:text-2xl ">{props.title}</h1>
          <p className="text-sm max-w-lg lg:max-w-max text-white text-opacity-75">
            {props.description}
          </p>
        </div>
      </div>
    </div>
  );
};
export default PageTitle;
