import { ReactComponent as ErrorBlack } from "../../../../assets/img/icon/help_black_24dp.svg";
import { COLOR } from "../../../../configs/constant";

const Confirm = (props) => {
  const handleClose = () => {
    if (props.handleClose) props.handleClose();
  };

  const handleConfirm = () => {
    if (props.data.callback) props.data.callback();
  };

  return (
    <div
      className={
        "fixed top-0 left-0 right-0 min-h-screen bg-gray-900 bg-opacity-40 px-2 " +
        (props.data.show ? "opacity-100" : "opacity-0")
      }
      style={{ zIndex: props.data.show ? "30" : "-10" }}
    >
      <div
        className={
          "max-w-sm md:max-w-xl bg-white shadow mx-auto px-4 sm:px-10 py-5 sm:py-6 rounded relative duration-75 ease-in mt-16 transform " +
          (props.data.show ? "opacity-100 scale-105" : "opacity-30")
        }
      >
        <div>
          <ErrorBlack
            fill={COLOR.INFO}
            className="w-20 sm:w-24 h-20 sm:h-24 mx-auto"
          />
        </div>

        <div className="mt-3 text-center max-w-2xl mx-auto">
          <h3 className="font-semibold text-xl capitalize">Perhatian</h3>
          <p className="mt-1">
            {props.data.message || "Nampaknya ada yang salah."}
          </p>
        </div>
        <div className="mt-5 flex space-x-2 justify-center">
          <button
            className="w-20 px-2 py-0.5 bg-warning rounded text-white focus:outline-none focus:ring-2"
            onClick={handleClose}
          >
            Batal
          </button>
          <button
            className="w-20 px-2 py-0.5 bg-secondary rounded text-white focus:outline-none focus:ring-2"
            onClick={handleConfirm}
          >
            Ya
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
