import {toast} from "react-toastify";

class ToastifyService {
    static run(type, message) {
        toast[`${type}`](`${message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

export default ToastifyService;