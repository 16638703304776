import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ReactComponent as AddCircle } from "../../../../../assets/img/icon/add_circle_black_24dp.svg";
import { ReactComponent as Board } from "../../../../../assets/img/icon/developer_board_black_24dp.svg";
import { ReactComponent as ListIcon } from "../../../../../assets/img/icon/format_list_bulleted_black_24dp.svg";
import { CONFIG } from "../../../../../configs/constant";
import { getDateFormat2, getDateFormat3 } from "../../../../../helpers/helper";
import { getDevice } from "../../../../../services/httpServices/deviceService";
import { client } from "../../../../../services/mqttServices/mqttService";
import CardDashboard from "../../../../ui/atoms/CardDashboard/CardDashboard";
import PageTitle from "../../../../ui/atoms/PageTitle/PageTitle";
import ModalDeviceAdd from "../../../../ui/molecules/ModalDeviceAdd/ModalDeviceAdd";

const DeviceList = () => {
  let myClient = client;
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [devices, setDevices] = useState(new Array(5).fill(""));

  const handleGetDevice = async () => {
    try {
      let { result } = await getDevice(`application=${CONFIG.appName}`);
      result = result.filter((obj) => !CONFIG.root.includes(obj.deviceName));
      result = result.map((obj) => {
        return {
          ...obj,
          createdAt: getDateFormat3(obj.createdAt),
        };
      });
      setDevices(result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleClick = (deviceName) => {
    history.push(`/dashboard/device/${deviceName}`);
  };

  useEffect(() => {
    handleGetDevice();
  }, []);

  return (
    <div className="mt-2 lg:mt-6">
      <PageTitle
        title="Halaman Perangkat"
        description="Dapatkan informasi tentang daftar perangkat smartmeter."
        icon={<Board fill="#fafafa" className="w-12 lg:w-16 h-12 lg:h-16" />}
      />

      <br />
      <ModalDeviceAdd
        show={modal}
        handleClose={() => setModal(false)}
        handleUpdate={() => {
          handleGetDevice();
          setModal(false);
        }}
      />
      <div className="flex justify-end items-center">
        <button
          className="flex items-center focus:outline-none text-primary"
          onClick={() => setModal(true)}
        >
          <AddCircle className="fill-current w-5 h-5" />
          <div className="ml-1">Tambah Perangkat</div>
        </button>
      </div>
      <div className="mt-6">
        <CardDashboard
          title="Daftar Perangkat"
          icon={<ListIcon fill="#fafafa" className="w-6 h-6" />}
          className="mt-5 col-span-12 pt-5 pb-4"
        >
          <div className="overflow-x-auto">
            <table className="w-full mt-3">
              <thead>
                <tr className="border-t bg-red-900 text-white">
                  <th className="text-left px-2 py-3">Tanggal Registrasi</th>
                  <th className="text-left px-2 py-3">Perangkat</th>
                  <th className="text-left px-2 py-3">Penempatan</th>
                  <th className="text-left px-2 py-3">Deskripsi Singkat</th>
                  <th className="text-left px-2 py-3">Koordinat Lokasi</th>
                </tr>
              </thead>
              <tbody>
                {devices.map((device, idx) => {
                  if (device._id === undefined)
                    return (
                      <tr className="" key={"dummy-device-" + idx}>
                        <td className="px-2 py-2">
                          <div className="bg-primary animate-pulse h-6 rounded" />
                        </td>
                        <td className="px-2 py-2">
                          <div className="bg-primary animate-pulse h-6 rounded" />
                        </td>
                        <td className="px-2 py-2">
                          <div className="bg-primary animate-pulse h-6 rounded" />
                        </td>
                        <td className="px-2 py-2">
                          <div className="bg-primary animate-pulse h-6 rounded" />
                        </td>
                        <td className="px-2 py-2">
                          <div className="bg-primary animate-pulse h-6 rounded" />
                        </td>
                      </tr>
                    );
                  return (
                    <tr
                      key={"device-" + device._id}
                      className={
                        "cursor-pointer border-b  hover:bg-primary hover:bg-opacity-20 " +
                        (idx === 0 ? "border-t" : "") +
                        (idx % 2 === 0 ? " bg-primary bg-opacity-10" : "")
                      }
                      onClick={() => handleClick(device.deviceName)}
                    >
                      <td className="px-2 py-2">{device.createdAt}</td>
                      <td className="px-2 py-2">{device.deviceName}</td>
                      <td className="px-2 py-2">
                        {device.description ? device.description.label : "-"}
                      </td>
                      <td className="px-2 py-2">
                        {device.description ? device.description.detail : "-"}
                      </td>
                      <td className="px-2 py-2">
                        {device.position ? (
                          <span className="bg-secondary text-white rounded-md inline-block px-1.5">
                            Telah Diatur
                          </span>
                        ) : (
                          <span className="bg-warning text-white rounded-md inline-block px-1.5">
                            Belum Diatur
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </CardDashboard>
      </div>
    </div>
  );
};

export default DeviceList;
