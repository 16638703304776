import FileDownload from "js-file-download";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ReactComponent as ChevronLeft } from "../../../../../assets/img/icon/chevron_left_black_24dp.svg";
import { ReactComponent as ChevronRight } from "../../../../../assets/img/icon/chevron_right_black_24dp.svg";
import { ReactComponent as FileDownloadIcon } from "../../../../../assets/img/icon/file_download_black_24dp.svg";
import { ReactComponent as FilterIcon } from "../../../../../assets/img/icon/filter_alt_black_24dp.svg";
import { COLOR, CONFIG } from "../../../../../configs/constant";
import {
  formatter,
  getDateFormat2,
  getDateTimeFormat,
} from "../../../../../helpers/helper";
import {
  getPayload,
  postDownloadPayload,
} from "../../../../../services/httpServices/payloadService";
import { GlobalContext } from "../../../../contexts/globalContext";
import ModalPayloadFilter from "../../../molecules/ModalPayloadFilter/ModalPayloadFilter";

const LIMIT = 10;

const DeviceSensorData = (props) => {
  const [sharedState, dispatch] = useContext(GlobalContext);
  const [state, setState] = useState({
    page: 1,
    tpage: 0,
    count: 0,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [payload, setPayload] = useState(new Array(LIMIT).fill(""));
  const params = useParams();
  const handleGetPayload = async (input) => {
    try {
      let offset = 0;
      let page = state.page;
      if (input === "next") {
        setPayload(new Array(LIMIT).fill(""));
        page += 1;
      } else if (input === "prev") {
        setPayload(new Array(LIMIT).fill(""));
        page -= 1;
      } else if (input === "refresh") {
        page = 1;
      }
      offset = (page - 1) * LIMIT;
      let param = `topics=${CONFIG.appName} - ${params.deviceName}&limit=${LIMIT}&offset=${offset}`;
      if (
        state.startDate !== "" &&
        state.startTime !== "" &&
        state.endDate !== "" &&
        state.endTime !== ""
      ) {
        let tStartDate = new Date(`${state.startDate} ${state.startTime}`);
        let tEndDate = new Date(`${state.endDate} ${state.endTime}`);

        if (tStartDate.getTime() >= tEndDate.getTime()) {
          throw new Error("Invalid Range");
        }

        tStartDate = getDateTimeFormat(tStartDate);
        tEndDate = getDateTimeFormat(tEndDate);
        param = `${param}&startDate=${tStartDate}&endDate=${tEndDate}`;
      }

      let { result, count } = await getPayload(param);

      count = count[`${CONFIG.appName} - ${params.deviceName}`];
      const tpage = Math.ceil(count / LIMIT);

      let payload = result[`${CONFIG.appName} - ${params.deviceName}`];
      payload = payload.map((obj) => ({
        ...obj,
        timestamp: getDateFormat2(obj.timestamp),
      }));

      setPayload(payload);
      setState((prevState) => ({ ...prevState, page, tpage, count }));
      if (showModal) setShowModal(false);
    } catch (error) {
      // console.log(error.message);
      dispatch("alert", {
        show: true,
        message: error.message,
        type: "warning",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDownloadPayload = async () => {
    try {
      if (state.count > CONFIG.downloadLimit)
        throw new Error(
          "Permintaan unduhan melebihi batas maksimum. Tolong ubah waktunya."
        );

      if (
        state.startDate === "" ||
        state.startTime === "" ||
        state.endDate === "" ||
        state.endTime === ""
      )
        throw new Error(
          "Rentang waktu wajib diisi. Silakan atur pada formulir filter."
        );

      let tStartDate = new Date(`${state.startDate} ${state.startTime}`);
      let tEndDate = new Date(`${state.endDate} ${state.endTime}`);

      if (tStartDate.getTime() >= tEndDate.getTime()) {
        throw new Error("Rentang Tidak Valid");
      }
      dispatch("isLoading", true);

      const resp = await postDownloadPayload({
        application: CONFIG.appName,
        device: params.deviceName,
        startDate: getDateTimeFormat(tStartDate),
        endDate: getDateTimeFormat(tEndDate),
        dataKey: CONFIG.variable.map((obj) => obj.key),
      });
      // console.log(resp);
      FileDownload(resp, `payload-${CONFIG.appName}-${params.deviceName}.csv`);
      dispatch("isLoading", false);
    } catch (error) {
      dispatch("isLoading", false);
      dispatch("alert", {
        show: true,
        type: "warning",
        message: error.message,
      });
    }
  };

  useEffect(() => {
    handleGetPayload();
  }, []);

  return (
    <div>
      <ModalPayloadFilter
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={() => handleGetPayload("refresh")}
        handleChange={handleChange}
        startDate={state.startDate}
        startTime={state.startTime}
        endDate={state.endDate}
        endTime={state.endTime}
      />
      {payload.length === 0 ? (
        <p>Data sensor tidak ditemukan.</p>
      ) : (
        <div className="relative pt-8">
          <div className="absolute top-0 right-0 w-full flex items-center justify-between">
            <div
              className="flex items-center space-x-1 cursor-pointer"
              onClick={() => setShowModal(true)}
            >
              <FilterIcon fill={COLOR.PRIMARY} className="w-5 h-5" />
              <div>Filter Data</div>
            </div>
            <div className="flex items-center space-x-3 justify-end">
              <div className="">{`Halaman ${state.page} dari ${state.tpage} `}</div>
              <div
                className="cursor-pointer flex items-center space-x-1"
                onClick={handleDownloadPayload}
              >
                <FileDownloadIcon fill={COLOR.PRIMARY} className="w-5 h-5" />
                <div>Unduh</div>
              </div>
            </div>
          </div>
          <div
            className="overflow-x-auto"
            style={{
              minHeight: "28rem",
            }}
          >
            <table className="w-full">
              <thead>
                <tr className="bg-red-900 text-white">
                  <th className="text-left px-2 py-2 w-44" rowSpan="2">
                    Waktu
                  </th>
                  <th
                    className="text-left px-2 py-2"
                    colSpan={CONFIG.variable.length}
                  >
                    Data
                  </th>
                </tr>
                <tr className="border-t bg-red-900 text-white">
                  {CONFIG.variable.map((obj, idx) => (
                    <th
                      key={"thead-" + obj.key}
                      className="text-left px-2 py-2 w-16"
                    >
                      {obj.key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {payload.map((device, idx) => {
                  if (device._id === undefined)
                    return (
                      <tr className="" key={"dummy-device-" + idx}>
                        <td className="px-2 py-2">
                          <div className="bg-primary animate-pulse h-6 rounded" />
                        </td>
                        {CONFIG.variable.map((obj, id) => (
                          <td key={"an-dummy-" + id + obj.key + idx}>
                            <div className="bg-primary animate-pulse h-6 rounded " />
                          </td>
                        ))}
                      </tr>
                    );
                  return (
                    <tr
                      key={"device-" + device._id}
                      className={
                        "border-b  " +
                        (idx === 0 ? "border-t " : "") +
                        (idx % 2 === 0 ? "bg-primary bg-opacity-10" : "")
                      }
                    >
                      <td className="px-2 py-2">{device.timestamp}</td>
                      {CONFIG.variable.map((obj, idx) => (
                        <td
                          key={"an-payload-" + obj.key + idx + device._id}
                          className="px-2 py-2"
                        >
                          {device["payload"][obj.key]
                            ? formatter.format(device["payload"][obj.key])
                            : "-"}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="mt-3 flex justify-between">
            <button
              className={
                "px-2 py-0.5 bg-secondary rounded-l text-white focus:outline-none flex items-center " +
                (state.page <= 1 ? "bg-opacity-25 cursor-not-allowed" : "")
              }
              onClick={() => handleGetPayload("prev")}
              disabled={state.page <= 1}
            >
              <ChevronLeft fill="#fafafa" className="w-5 h-5" />
              <div>Prev</div>
            </button>

            <button
              className={
                "px-2 py-0.5 bg-secondary rounded-r text-white right-0 focus:outline-none flex items-center " +
                (state.page === state.tpage || state.tpage <= 1
                  ? "bg-opacity-50 cursor-not-allowed"
                  : "")
              }
              onClick={() => handleGetPayload("next")}
              disabled={state.page === state.tpage || state.tpage <= 1}
            >
              <div>Next</div>
              <ChevronRight fill="#fafafa" className="w-5 h-5" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceSensorData;
