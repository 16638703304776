import { Link } from "react-router-dom";
// import { ReactComponent as FolderIcon } from "../../../assets/img/icon/folder_black_24dp.svg";
import { ReactComponent as BarChartIcon } from "../../../assets/img/icon/bar_chart_black_24dp.svg";
import { ReactComponent as Dashboard } from "../../../assets/img/icon/dashboard_black_24dp.svg";
import { ReactComponent as Board } from "../../../assets/img/icon/developer_board_black_24dp.svg";
import { ReactComponent as Person } from "../../../assets/img/icon/person_black_24dp.svg";
import AsideDashboard from "../../ui/organisms/AsideDashboard/AsideDashboard";
import NavbarDashboard from "../../ui/organisms/NavbarDashboard/NavbarDashboard";
import BasicTemplate from "../BasicTemplates/BasicTemplates";

const iconStyle =
  "sm:w-8 xl:w-10 h-8 xl:h-10 mx-auto fill-current text-white xl:text-primary";
const PAGE_LIST = [
  {
    label: "Dasbor",
    icon: <Dashboard className={iconStyle} />,
    path: "/dashboard",
  },
  {
    label: "Perangkat",
    icon: <Board className={iconStyle} />,
    path: "/dashboard/device",
  },
  {
    label: "Statistik",
    icon: <BarChartIcon className={iconStyle} />,
    path: "/dashboard/statistic",
  },
  {
    label: "Profil",
    icon: <Person className={iconStyle} />,
    path: "/dashboard/profile",
  },
];

const DashboardTemplate = (props) => {
  return (
    <BasicTemplate className="pt-5 min-h-screen overflow-x-hidden text-sm sm:text-base ">
      <NavbarDashboard />
      <div className="pt-12 bg-white px-1 md:px-3 container mx-auto flex flex-col-reverse xl:flex-row xl:space-x-6">
        <AsideDashboard />

        <section className="px-1 md:px-3 xl:pl-6 xl:pr-12 py-3 md:py-6 w-full">
          <div className="overflow-x-auto py-1.5 px-2 sm:px-16 md:px-36 xl:px-0 fixed z-10 border-t xl:border-t-0 bottom-0 xl:relative xl:bottom-auto left-0 xl:left-auto bg-gray-700 xl:bg-transparent  w-full xl:w-auto">
            <div className="flex justify-between xl:justify-start space-x-2 xl:space-x-5 xl:border-b">
              {PAGE_LIST.map((page) => (
                <Link to={page.path} key={page.label}>
                  <div
                    className="relative px-1 py-0.5 flex-shrink-0 cursor-pointer rounded hover:bg-gray-600 xl:hover:bg-red-100"
                    style={{ minWidth: "5rem" }}
                  >
                    {page.icon}
                    <div className="text-xs md:text-sm text-center capitalize text-white xl:text-gray-700">
                      {page.label}
                    </div>
                    {page.label === props.active && (
                      <hr className="absolute w-full left-0 -bottom-1.5 xl:-bottom-1 xl:relative -mt-1 xl:mt-2 border-2 border-white xl:border-primary rounded-lg" />
                    )}
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="mt-3">{props.children}</div>
        </section>
      </div>
    </BasicTemplate>
  );
};

export default DashboardTemplate;
